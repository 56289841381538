import { createSlice } from '@reduxjs/toolkit';
import { asyncApiThunkFactory } from '../asyncThunkFactory';

const initialState = {
  data: {},
  totalItems: 0,
  hasLoading: false,
  hasError: null
};

const handlePendingPostOrPutOrDelete = (state) => {
  state.hasLoading = true;
};

const handleFulfilledPostOrPutOrDelete = (state) => {
  state.hasLoading = false;
  state.item = null;
};

const handleRejectPostOrPutOrDelete = (state, action) => {
  state.hasLoading = false;
  state.hasError = action.payload;
};

export const create = asyncApiThunkFactory('report_item_description_images/create', `${process.env.REACT_APP_REPORT_ITEM_DESCRIPTION_IMAGES_V1}`, 'post');
export const remove_images = asyncApiThunkFactory('report_item_description_images/remove_images', `${process.env.REACT_APP_REPORT_ITEM_DESCRIPTION_IMAGES_V1}/remove_images`, 'post');

const reportItemDescriptionImagesSlice = createSlice({
  name: 'report_item_description_images',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // create
      .addCase(create.pending, handlePendingPostOrPutOrDelete)
      .addCase(create.fulfilled, handleFulfilledPostOrPutOrDelete)
      .addCase(create.rejected, handleRejectPostOrPutOrDelete)
      // remove_images
      .addCase(remove_images.pending, handlePendingPostOrPutOrDelete)
      .addCase(remove_images.fulfilled, handleFulfilledPostOrPutOrDelete)
      .addCase(remove_images.rejected, handleRejectPostOrPutOrDelete)
  },
});

export default reportItemDescriptionImagesSlice.reducer;