import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
/* Common */
import { optionsRadioTestFunctionality } from "@/common/constants/index";
/* Common / Lauyouts */
import ContentHeader from "@/common/layouts/ContentHeader";
import Content from "@/common/layouts/Content";
import Row from "@/common/layouts/Row";
import Grid from "@/common/layouts/Grid";
/* Common / Form */
import Select from "@/common/form/controls/Select";
import {
  InputLabel,
  InputRadio,
  TextArea,
  useInputControl,
} from "@/common/form/controls/Input";
/* Components */
import Card from "@/components/Card";
import { ButtonGroup } from "@/components/ButtonGroup";
import { Button } from "@/components/Button";
/* Tests */
import {
  useTestFunctionality,
  formFunctionalityTest as optionsFunctionalityTest,
} from "../Tests/StoreTests";
/* Reducers */
import { show as showReport } from "@/reducers/Reports/ReportsReducer";
import {
  create,
  update,
  show as showFunctionalityTest,
} from "@/reducers/Reports/FunctionalityTestsReducer";

import { useCalloutReport } from "@/pages/Reports/CalloutReport";
import FormGroup from "@/common/form/FormGroup";

function Index() {
  const { report_id, id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    inputsForm,
    testFunctionality,
    setTestFunctionality,
    handleInputTestFunctionality,
  } = useTestFunctionality();
  const { setReportInfo, CalloutReport } = useCalloutReport();
  const {
    errors,
    hasEmptyFields,
    inputValidator,
    isFormValid,
    imagePreviews,
    setImagePreviews,
    images,
    setImages,
  } = useInputControl();

  useEffect(() => {
    setTestFunctionality({
      item: "",
      report_id,
      description: "",
      images,
    });
    if (!id)
      dispatch(showReport({ id: report_id })).then(({ payload }) => {
        setReportInfo(payload);
      });
    else
      dispatch(showFunctionalityTest({ id })).then(({ payload }) => {
        setReportInfo(payload.report);
        setTestFunctionality(payload);
      });
    // eslint-disable-next-line
  }, [id, report_id]);

  useEffect(() => {
    if (hasEmptyFields(testFunctionality)) {
      const formValidators = inputsForm.map((input) => ({
        ...input,
        validators: [{ required: true }],
      }));
      inputValidator(testFunctionality, formValidators);
    }
    // eslint-disable-next-line
  }, [testFunctionality, inputsForm]);

  function submit() {
    dispatch(id ? update(testFunctionality) : create(testFunctionality)).then(
      () =>
        navigate(`/functionality_tests/${testFunctionality.report_id}/report`)
    );
  }

  return (
    <>
      <ContentHeader
        title={`${id ? "Editar" : "Incluir "} Teste de Funcionalidade`}
        previousRoute={`/functionality_tests/${testFunctionality.report_id}/report`}
      />
      <Content>
        <CalloutReport hideReportItems />
        <Card
          title="Detalhes"
          iconTitle="list-alt"
          cardTools={
            <ButtonGroup>
              <Button
                icon="eraser"
                btnClass=" btn-tool"
                dataTooltipId="functionality-test-clean"
                dataTooltipContent="Limpar"
              />
              <Button
                icon="save"
                btnClass=" btn-tool"
                dataTooltipId="functionality-test-save"
                dataTooltipContent="Salvar"
                disabled={!isFormValid}
                onClick={() => submit()}
              />
            </ButtonGroup>
          }
          cardBodyClass=" pt-0"
        >
          <Row>
            <Grid cols="12 12">
              <Select
                label="Teste do item:"
                value={testFunctionality.item}
                hasFirstOptionEmpty
                options={optionsFunctionalityTest.map(({ item }) => ({
                  text: item,
                  value: item,
                }))}
                onChange={(e) =>
                  setTestFunctionality({
                    ...testFunctionality,
                    item: e.target.value,
                  })
                }
              />
            </Grid>
          </Row>
          {testFunctionality.item && (
            <Row rowClass=" pt-4">
              {inputsForm.map(
                (
                  { type, label, name, value, cols, title, rows, resize },
                  i
                ) => (
                  <React.Fragment key={i}>
                    {title && (
                      <Grid cols="12 12">
                        <label>{title}</label>
                      </Grid>
                    )}
                    <Grid key={i} cols={cols ?? "12 12"}>
                      {(() => {
                        switch (type) {
                          case "text":
                            return (
                              <InputLabel
                                value={testFunctionality[name]}
                                {...{ label, name, errors }}
                                onChange={handleInputTestFunctionality}
                              />
                            );
                          case "textarea":
                            return (
                              <TextArea
                                value={testFunctionality[name]}
                                {...{
                                  label,
                                  name,
                                  rows,
                                  resize,
                                  errors,
                                }}
                                onChange={handleInputTestFunctionality}
                              />
                            );
                          default:
                            return (
                              <InputRadio
                                type="radio"
                                value={testFunctionality[name]}
                                {...{ label, name, errors }}
                                options={optionsRadioTestFunctionality.map(
                                  (radio) => ({
                                    label: radio,
                                    value: radio,
                                  })
                                )}
                                onChange={handleInputTestFunctionality}
                              />
                            );
                        }
                      })()}
                    </Grid>
                  </React.Fragment>
                )
              )}
              <Grid cols="12 12">
                <TextArea
                  label="Observação"
                  name="observation"
                  value={testFunctionality.observation}
                  rows={4}
                  resize="none"
                  onChange={handleInputTestFunctionality}
                />
              </Grid>
              {!id && (
                <>
                  <Grid cols="12 12">
                    <FormGroup>
                      <label>Imagens:</label>
                      <input
                        id="images"
                        name="images"
                        type="file"
                        multiple
                        accept="image/*"
                        className="form-control"
                        onChange={(e) => {
                          const files = Array.from(e.target.files);
                          setImages(files);
                          const previews = files.map((file) =>
                            URL.createObjectURL(file)
                          );
                          setImagePreviews(previews);
                        }}
                      />
                      {!images.length && (
                        <div className="invalid-feedback">
                          Campo obrigatório.
                        </div>
                      )}
                    </FormGroup>
                  </Grid>
                  {imagePreviews &&
                    imagePreviews.map((imageSrc, index) => (
                      <Grid key={index} cols="12 3">
                        <img
                          src={imageSrc}
                          alt={`Imagem ${index}`}
                          className="img-fluid"
                        />
                      </Grid>
                    ))}
                </>
              )}
            </Row>
          )}
        </Card>
      </Content>
    </>
  );
}

export default Index;
