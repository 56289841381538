import { useState } from "react";

export const useModal = () => {
    const [modalData, setModalData] = useState({
        modalId: '',
        modalShow: false,
        modalTitle: '',
        modalContent: null,
        modalActions: null,
    });

    return {
        modalData,
        setModalData
    }
}