import React from "react";
import Logo from "@/assets/images/logo.png";
import { menus } from "../constants/menus";
import MenuTree from "./MenuTree";
import MenuItem from "./MenuItem";

const SideBar = () => {
  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      <a href="/dashboard" className="brand-link">
        <img
          src={Logo}
          alt="Logo Small"
          className="brand-image elevation-3 logo-xs"
        />

        <span className="brand-text">
          <div className="d-flex">
            <>
              <img
                src={Logo}
                alt="Logo Large"
                className="brand-image elevation-3"
              />
            </>
            <>
              My<strong>LinceEng</strong>
            </>
          </div>
        </span>
      </a>
      <div className="sidebar">
        <nav className="mt-2">
          <ul className="nav nav-pills nav-sidebar flex-column" role="menu">
          {menus.map(({ header, items }, key) => (
          <React.Fragment key={key}>
            {header && <li className="nav-header">{header}</li>}
            {items
              .filter(({ visible }) => visible)
              .map(({ label, style, icon, path, childrens }, idx) => {
                return style === "treeview" ? (
                  <MenuTree
                    key={idx}
                    {...{ label, icon, path, childrens }} 
                  />
                ) : (
                  <MenuItem key={idx} {...{ label, icon, path }} />
                );
              })}
          </React.Fragment>
        ))}
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default SideBar;
