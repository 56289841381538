import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom"; 
import { useDispatch, useSelector } from "react-redux";
/* Common / Form */
import Select from "@/common/form/controls/Select";
import {
  InputCheckbox,
  InputGroupAppend,
} from "@/common/form/controls/Input";
/* Common / Layouts */
import ContentHeader from "@/common/layouts/ContentHeader";
import Content from "@/common/layouts/Content";
import Grid from "@/common/layouts/Grid";
/* Components */
import Card from "@/components/Card";
import { DropdownItem } from "@/components/DropdownItem";
import { Button } from "@/components/Button";
import { ButtonGroup } from "@/components/ButtonGroup";
import Image from "@/components/Image";
/* Components / Table */
import { useTableFilterSearch } from "@/components/Table/TableFilterSearch";
import { Table } from "@/components/Table/Table";
import { Tooltip } from "react-tooltip";
import {
  useHandleRowImages,
  RowImages,
} from "@/components/Table/RowImages";
/* Components / Modal */
import { Modal as ModalDeleteVisit } from "@/components/Modal/Modal";
import { useModal } from "@/components/Modal/ModalStore";
import Row from "@/common/layouts/Row";
/* Reducers */
import { index, remove } from "@/reducers/Inspections/InspectionVisitsReducer";
import { remove as remove_images } from "../../../../reducers/Inspections/InspectionVisitImagesReducer";

import CalloutInspection from "../../CalloutInspection";

function Index() {
  const dispatch = useDispatch();
  const { inspection_id } = useParams();
  const { data } = useSelector((state) => state.inspectionVisits);
  const { inspection, dates_of_inspections, inspection_visits } = data || "";

  const {
    filterSearch,
    handleTableFilterSearch,
    setFilterSearch,
    handleInputFilterSearch,
  } = useTableFilterSearch();

  const { modalData, setModalData } = useModal();

  const optStatusInspectionHistorics = [
    "As-Built",
    "Concluído",
    "Em andamento",
    "Finalizado",
    "Informativo",
    "Informativo/Acompanhamento",
    "Observação",
    "Pendente",
  ];

  const fields = [
    {
      key: "item",
      label: "Item",
      thClass: "text-center w-10",
      tdClass: "text-center",
    },
    {
      key: "description",
      label: "Descrição",
      thClass: "w-50",
      isFiltered: true,
    },
    {
      key: "responsible",
      label: "Responsável",
      thClass: "d-none d-md-table-cell",
      tdClass: "d-none d-md-table-cell",
      isFiltered: true,
    },
    {
      key: "date_of_visit",
      label: "Data da visita",
      thClass: "d-none d-md-table-cell text-center w-45",
      tdClass: "d-none d-md-table-cell text-center",
    },
  ];


  useEffect(() => {
    onFilterSearch();
    // eslint-disable-next-line
  }, [
    filterSearch.params.status,
    filterSearch.params.date_of_visit,
    filterSearch.params.currentPage,
    filterSearch.params.itemsPerPage,
  ]);

  const onFilterSearch = () => {
    const filterSearchURL = {
      params: {
        ...filterSearch.params,
        fields: filterSearch.params.fields ?? ["responsible", "description"].join("?"),
        inspection_id,
      }
    }
    setFilterSearch(filterSearchURL);
    dispatch(index(filterSearchURL));
  };

  const TableInspectionHistorics = () => {
    const RowsInspectionHistorics = () => {
      const [expandedRow, setExpandedRow] = useState(null);
      const toggleRow = (id) => {
        setExpandedRow(expandedRow === id ? null : id);
      };

      const {
        selectedImages,
        setSelectedImages,
        confirmRemoveImages,
        setConfirmRemoveImages,
      } = useHandleRowImages();

      const handleRemoveImages = () => {
        dispatch(remove_images({ images_id: selectedImages.join("?") })).then(
          () => {
            onFilterSearch();
          }
        );
      };

      return inspection_visits.data.map((row, idx) => (
        <React.Fragment key={idx}>
          <tr>
            {fields.map(({ key, tdClass }, idx) => (
              <td key={idx} className={tdClass}>
                {row[key]}
              </td>
            ))}
            <td>
              <ButtonGroup>
                <Button
                  btnClass=" btn-tool"
                  onClick={() => toggleRow(row.id)}
                  dataTooltipId={`visit_${row.id}-images`}
                  dataTooltipContent="Imagens"
                >
                  <div className="d-flex">
                    <i className="fas fa-images mr-2"></i>
                    <i
                      className={`fa ${
                        expandedRow === row.id ? "fa-caret-up" : "fa-caret-down"
                      }`}
                    ></i>
                  </div>
                </Button>
                <a
                  href={`/visits/${row.id}/edit/inspection/${inspection_id}`}
                  className="btn btn-tool"
                  data-tooltip-id="tooltip-report-edit"
                  data-tooltip-content="Editar"
                >
                  <i className="fas fa-pen mr-2"></i>
                </a>
                <Tooltip id="tooltip-report-edit" />
                <Button
                  btnClass=" btn-tool"
                  icon="trash-alt"
                  dataTooltipId="report-delete"
                  dataTooltipContent="Apagar"
                  onClick={() =>
                    setModalData({
                      ...modalData,
                      modalShow: !modalData.modalShow,
                      modalContent: row,
                    })
                  }
                />
              </ButtonGroup>
            </td>
          </tr>
          {expandedRow === row.id && (
            <RowImages
              colSpan={fields.length}
              content={
                <>
                  <Row rowClass=" mb-3">
                    <Grid cols="12 4">
                      <ButtonGroup>
                        <a href={`/visit/${row.id}/images`} className="btn-sm">
                          <i className="fas fa-plus mr-2"></i>Incluir imagens
                        </a>
                        <Tooltip id="tooltip-description-create-images" />
                        <Button
                          btnClass=" btn-sm text-danger"
                          label="Excluir imagens"
                          icon="trash-alt"
                          disabled={!selectedImages.length}
                          onClick={() => setConfirmRemoveImages(true)}
                        />
                      </ButtonGroup>
                    </Grid>
                    {confirmRemoveImages && (
                      <Grid cols="12 8">
                        <span>
                          Tem certeza que deseja excluir as imagens
                          selecionadas?
                        </span>
                        <hr />
                        <ButtonGroup>
                          <Button
                            btnClass=" btn-sm btn-tool"
                            icon="check"
                            label="Sim"
                            onClick={handleRemoveImages}
                          />
                          <Button
                            btnClass=" btn-sm btn-tool"
                            icon="times"
                            label="Não"
                            onClick={() => setConfirmRemoveImages(false)}
                          />
                        </ButtonGroup>
                      </Grid>
                    )}
                  </Row>
                  {!row.inspection_visit_images.length ? (
                    <div className="my-3">
                      <span>Nenhuma imagem encontrada.</span>
                    </div>
                  ) : (
                    <Row>
                      {row.inspection_visit_images.map(({ id, image }, idx) => (
                        <Grid key={idx} cols="12 4">
                          <InputCheckbox
                            value={id}
                            onChange={(e) => {
                              const { value } = e.target;
                              setSelectedImages((prev) => {
                                setConfirmRemoveImages(false);
                                if (prev.includes(value)) {
                                  return prev.filter((item) => item !== value);
                                } else {
                                  return [...prev, value];
                                }
                              });
                            }}
                          />
                          <Image
                            imageURL={`${process.env.REACT_APP_INSPECTION_VISIT_IMAGES_V1}?inspection_id=${inspection?.id}&inspection_visit_id=${row.id}&filename=${image}`}
                            filename={image}
                          />
                        </Grid>
                      ))}
                    </Row>
                  )}
                </>
              }
            />
          )}
        </React.Fragment>
      ));
    };

    return (
      <Table
        {...{ fields }}
        tableClass=" table-striped table-valign-middle"
        rows={<RowsInspectionHistorics />}
        HasPagination
        paginationClass=" justify-content-center"
        totalItems={inspection_visits?.total}
        itemsPerPage={filterSearch.params.itemsPerPage}
        currentPage={filterSearch.params.currentPage}
        onItemsPerPageChange={(key, value) =>
          handleTableFilterSearch(key, value)
        }
        onPageChange={(currentPage) =>
          handleTableFilterSearch("currentPage", currentPage)
        }
      />
    );
  };

  return (
    <>
      <ContentHeader
        title="Histórico de Visitas"
        subtitle={`${
          filterSearch.params?.status ? `${filterSearch.params.status} | ` : ""
        } Total: ${inspection_visits?.total ?? 0}`}
        previousRoute="/inspections"
      />
      <Content>
        <CalloutInspection
          id={inspection_id}
          title={inspection?.title}
          company={inspection?.company}
        />
        <Card
          cardTitle={
            <div className="card-title">
              <a
                href={`/visits/inspection/${inspection_id}/create`}
                className="btn btn-tool"
                data-tooltip-id="tooltip-visit-create"
                data-tooltip-content="Incluir"
              >
                <i className="fas fa-plus"></i>
              </a>
              <Tooltip id="tooltip-visit-create" />
              <DropdownItem
                icon="filter"
                tooltip="Filtro"
                tooltipId="filter-inspections"
                dropdownClass=" p-3 dropdown-menu-lg"
              >
                  <>
                    <Select
                      label="Status:"
                      name="status"
                      selectClass=" form-control-sm"
                      hasFirstOptionEmpty
                      emptyText="Mostrar todos"
                      options={optStatusInspectionHistorics.map((status) => {
                        return { text: status, value: status };
                      })}
                      value={filterSearch.params.status}
                      onChange={handleInputFilterSearch}
                    />
                    <div className="dropdown-divider my-3"></div>
                    <Select
                      label="Data da vistoria:"
                      name="date_of_visit"
                      selectClass=" form-control-sm"
                      hasFirstOptionEmpty
                      emptyText="Mostrar todos"
                      options={
                        dates_of_inspections?.map(({ text, value }) => {
                          return { text, value };
                        }) ?? []
                      }
                      value={filterSearch.params.date_of_visit}
                      onChange={handleInputFilterSearch}
                    />
                    <div className="dropdown-divider my-3"></div>
                    <label>Filtrar por:</label>
                    {fields
                      .filter(({ isFiltered }) => isFiltered)
                      .map(({ key, label }, idx) => (
                        <InputCheckbox
                          name="fields"
                          key={idx}
                          {...{ label, value: key }}
                          checked={
                            filterSearch.params.fields
                              ? filterSearch.params.fields.split("?").some(
                                  (field) => field === key
                                )
                              : false
                          }
                          onChange={handleInputFilterSearch}
                        />
                      ))}
                  </>
              </DropdownItem>
            </div>
          }
          cardTools={
            <div className="my-2">
              <InputGroupAppend
                type="search"
                name="search"
                icon="search"
                value={filterSearch.params.search}
                onChange={handleInputFilterSearch}
                cursor
                placeholder="Procurar..."
                inputClass=" form-control-sm"
                dataTooltipId="search-inspections"
                dataTooltipContent="Pesquisar"
                onClickSpan={onFilterSearch}
              />
            </div>
          }
          cardBodyClass=" table-responsive p-0"
        >
          <TableInspectionHistorics />
        </Card>
      </Content>
      <ModalDeleteVisit
        modalId="visit-delete"
        modalShow={modalData.modalShow}
        modalTitle="Excluir Visita"
        modalBody={
          modalData.modalContent && (
            <>
              <p>Tem certeza que deseja excluir essa visita?</p>
              <dl className="row">
                <dt className="col-sm-3 text-right">Item:</dt>
                <dd className="col-sm-9">{modalData.modalContent.item}</dd>
                <dt className="col-sm-3 text-right">Descrição:</dt>
                <dd className="col-sm-9">
                  {modalData.modalContent.description}
                </dd>
                <dt className="col-sm-3 text-right">Responsável:</dt>
                <dd className="col-sm-9">
                  {modalData.modalContent.responsible}
                </dd>
              </dl>
            </>
          )
        }
        hideModal={() => setModalData(false)}
        modalFooterClass=" d-flex justify-content-end"
        modalActions={
          <Button
            btnClass=" btn-primary"
            icon="check"
            label="Confirmar"
            onClick={() => {
              setModalData(false);
              dispatch(remove({ id: modalData.modalContent.id })).then(() =>
                onFilterSearch()
              );
            }}
          />
        }
      />
    </>
  );
}

export default Index;
