import React from 'react';
import './dependencies.js';

import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux'

import App from './App.jsx';
import Toastrs from "./components/Toastrs.jsx"

import store from './reducers/reducers.js'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
    <Toastrs />
  </Provider>
);