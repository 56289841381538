import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
/* Common / Layouts */
import ContentHeader from "@/common/layouts/ContentHeader";
import Content from "@/common/layouts/Content";
import { useTableFilterSearch } from "@/components/Table/TableFilterSearch";
import Row from "@/common/layouts/Row";
import Grid from "@/common/layouts/Grid";
/* Common / Form */
import { InputCheckbox, InputGroupAppend } from "@/common/form/controls/Input";
/* Components */
import Card from "@/components/Card";
import LinkTooltip from "@/components/LinkTooltip";
import { DropdownItem } from "@/components/DropdownItem";
import { ButtonGroup } from "@/components/ButtonGroup";
import { Button } from "@/components/Button";
import Image from "@/components/Image";
/* Components / Table */
import { Table } from "@/components/Table/Table";
import { RowImages, useHandleRowImages } from "@/components/Table/RowImages";
/* Reducers */
import { index } from "@/reducers/Reports/FunctionalityTestsReducer";

import { useCalloutReport } from "@/pages/Reports/CalloutReport";

function Index() {
  const { report_id } = useParams();
  const dispatch = useDispatch();
  const {
    filterSearch,
    handleTableFilterSearch,
    setFilterSearch,
    handleInputFilterSearch,
  } = useTableFilterSearch();
  const { data } = useSelector((state) => state.functionalityTests);
  const { functionalityTests, reportDetails } = data || "";
  const { setReportInfo, CalloutReport } = useCalloutReport();

  useEffect(() => {
    setReportInfo(reportDetails);
  },[reportDetails, setReportInfo])

  useEffect(() => {
    onFilterSearch();
    // eslint-disable-next-line
  }, [filterSearch.params.currentPage, filterSearch.params.itemsPerPage]);

  const onFilterSearch = () => {
    if(!+report_id){
      window.location.href = "/reports";
    }
    const filterSearchURL = {
      params: {
        ...filterSearch.params,
        report_id,
        fields:
          filterSearch.params.fields ??
          ["item", "tag", "model", "serie"].join("?"),
      },
    };
    setFilterSearch(filterSearchURL);
    dispatch(index(filterSearchURL));
  };

  const fields = [
    { key: "item", label: "Item" },
    {
      key: "tag",
      label: "Tag",
      thClass: "d-none d-md-table-cell",
      tdClass: "d-none d-md-table-cell",
    },
    {
      key: "model",
      label: "Modelo",
      thClass: "d-none d-md-table-cell",
      tdClass: "d-none d-md-table-cell",
    },
    {
      key: "serie",
      label: "Nº Serie",
      thClass: "d-none d-md-table-cell",
      tdClass: "d-none d-md-table-cell",
    },
  ];

  const TableFunctionalityTest = () => {
    const RowsFunctionalityTest = () => {
      const [expandedRow, setExpandedRow] = useState(null);
      const toggleRow = (id) => {
        setExpandedRow(expandedRow === id ? null : id);
      };

      const {
        selectedImages,
        setSelectedImages,
        confirmRemoveImages,
        setConfirmRemoveImages,
      } = useHandleRowImages();

      const handleRemoveImages = () => {
        // dispatch(remove_images({ images_id: selectedImages.join("?") })).then(
        //   () => {
        //     onFilterSearch();
        //   }
        // );
      };

      return functionalityTests?.data.map((row, idx) => (
        <React.Fragment key={idx}>
          <tr>
            {fields.map(({ key, tdClass }, idx) => (
              <td key={idx} className={tdClass}>
                {row[key]}
              </td>
            ))}
            <td>
              <ButtonGroup>
                <Button
                  btnClass=" btn-tool"
                  onClick={() => toggleRow(row.id)}
                  dataTooltipId={`functionality-test-images_${row.id}`}
                  dataTooltipContent="Imagens"
                >
                  <div className="d-flex">
                    <i className="fas fa-images mr-2"></i>
                    <i
                      className={`fa ${
                        expandedRow === row.id ? "fa-caret-up" : "fa-caret-down"
                      }`}
                    ></i>
                  </div>
                </Button>
                <LinkTooltip
                  path={`/functionality_test/${row.id}`}
                  icon="pencil-alt"
                  linkClass=" btn-tool"
                  dataTooltipId="functionality-test-edit"
                  dataTooltipContent="Editar"
                />
                <Button
                  btnClass=" btn-tool"
                  icon="trash-alt"
                  dataTooltipId="description-delete"
                  dataTooltipContent="Apagar"
                  // onClick={() =>
                  //   setModalData({
                  //     ...modalData,
                  //     modalShow: !modalData.modalShow,
                  //     modalContent: row,
                  //   })
                  // }
                />
              </ButtonGroup>
            </td>
          </tr>
          {expandedRow === row.id && (
            <RowImages
              colSpan={fields.length}
              content={
                <>
                  <Row rowClass=" mb-3">
                    <Grid cols="12 4">
                      <ButtonGroup>
                        <a
                          href={`/functionality_test/${row.id}/images`}
                          className="btn-sm"
                        >
                          <i className="fas fa-plus mr-2"></i>Incluir imagens
                        </a>
                        <Button
                          btnClass=" btn-sm text-danger"
                          label="Excluir imagens"
                          icon="trash-alt"
                          disabled={!selectedImages.length}
                          onClick={() => setConfirmRemoveImages(true)}
                        />
                      </ButtonGroup>
                    </Grid>
                    {confirmRemoveImages && (
                      <Grid cols="12 8">
                        <span>
                          Tem certeza que deseja excluir as imagens
                          selecionadas?
                        </span>
                        <hr />
                        <ButtonGroup>
                          <Button
                            btnClass=" btn-sm btn-tool"
                            icon="check"
                            label="Sim"
                            onClick={handleRemoveImages}
                          />
                          <Button
                            btnClass=" btn-sm btn-tool"
                            icon="times"
                            label="Não"
                            onClick={() => setConfirmRemoveImages(false)}
                          />
                        </ButtonGroup>
                      </Grid>
                    )}
                  </Row>
                  {!row.functionality_test_images.length ? (
                    <div className="text-center">
                      <span>Nenhuma imagem encontrada.</span>
                    </div>
                  ) : (
                    <Row>
                      {row.functionality_test_images.map(
                        ({ id, photo }, idx) => (
                          <Grid key={idx} cols="12 4">
                            <InputCheckbox
                              value={id}
                              onChange={(e) => {
                                const { value } = e.target;
                                setSelectedImages((prev) => {
                                  setConfirmRemoveImages(false);
                                  if (prev.includes(value)) {
                                    return prev.filter(
                                      (item) => item !== value
                                    );
                                  } else {
                                    return [...prev, value];
                                  }
                                });
                              }}
                            />
                            <Image
                              imageURL={`${process.env.REACT_APP_FUNCTIONALITY_TEST_IMAGES_V1}?report_id=${row.report_id}&functionality_test_id=${row.id}&item=${row.item}&filename=${photo}`}
                              filename={photo}
                            />
                          </Grid>
                        )
                      )}
                    </Row>
                  )}
                </>
              }
            />
          )}
        </React.Fragment>
      ));
    };

    return (
      <Table
        {...{ fields }}
        tableClass=" table-striped table-valign-middle"
        rows={<RowsFunctionalityTest />}
        HasPagination
        paginationClass=" justify-content-center"
        totalItems={functionalityTests?.total}
        itemsPerPage={filterSearch.params.itemsPerPage}
        currentPage={filterSearch.params.currentPage}
        onItemsPerPageChange={(key, value) =>
          handleTableFilterSearch(key, value)
        }
        onPageChange={(currentPage) =>
          handleTableFilterSearch("currentPage", currentPage)
        }
      />
    );
  };

  return (
    <>
      <ContentHeader
        title="Testes de Funcionalidade"
        previousRoute="/reports"
      />
      <Content>
        <CalloutReport hideReportItems />
        <Card
          cardTitle={
            <div className="card-title">
              <LinkTooltip
                path={`/functionality_test/${report_id}/report/create`}
                icon="plus"
                linkClass="btn-tool"
                dataTooltipId="functionality-test-create"
                dataTooltipContent="Incluir"
              />
              <DropdownItem
                icon="filter"
                tooltip="Filtro"
                tooltipId="filter-functionality-test"
                dropdownClass=" p-3 dropdown-menu-lg"
              >
                <>
                  <label>Filtrar por:</label>
                  {fields.map(({ key, label }, idx) => (
                    <InputCheckbox
                      key={idx}
                      name="fields"
                      {...{ label, value: key }}
                      checked={
                        filterSearch.params.fields
                          ? filterSearch.params.fields
                              .split("?")
                              .some((field) => field === key)
                          : false
                      }
                      onChange={handleInputFilterSearch}
                    />
                  ))}
                </>
              </DropdownItem>
            </div>
          }
          cardTools={
            <div className="my-2">
              <InputGroupAppend
                type="search"
                name="search"
                icon="search"
                value={filterSearch.params.search}
                onChange={handleInputFilterSearch}
                cursor
                placeholder="Procurar..."
                inputClass=" form-control-sm"
                dataTooltipId="search-inspections"
                dataTooltipContent="Pesquisar"
                onClickSpan={onFilterSearch}
              />
            </div>
          }
          cardBodyClass=" p-0"
        >
          <TableFunctionalityTest />
        </Card>
      </Content>
    </>
  );
}

export default Index;
