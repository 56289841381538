import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    text: null
};

const loadingSlice = createSlice({
    name: 'loading',
    initialState,
    reducers: {
        setLoadingText(state, action) {
            state.text = action.payload;
        },
        setLoading(state, action) {
            state.isLoading = action.payload;
        },
    }
})

export const { setLoading, setLoadingText } = loadingSlice.actions;

export default loadingSlice.reducer;