import React from "react";

function Card({ cardTitle, title, iconTitle, cardTools, cardBodyClass, children }) {
  return (
    <div className="card">
      <div className="card-header border-0">
          <>
          {cardTitle}
          {title && (
            <h3 className="card-title text-muted">
              <i className={`fas fa-${iconTitle} mr-2`}></i>
              {title}
            </h3>
          )}
          </>
        <div className="card-tools">{cardTools}</div>
      </div>
      <div className={`card-body${cardBodyClass ?? ""}`}>{children}</div>
    </div>
  );
}

export default Card;
