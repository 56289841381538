import React, { useEffect, useState } from "react";
import Header from "./common/templates/Header";
import Sidebar from "./common/templates/Sidebar";
import Footer from "./common/templates/Footer";
import Routes from "./routes/routes";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { validateToken } from "./reducers/Auth/AuthReducer";
import Loading from "./components/Loading/Index";
import Cookies from "js-cookie" 

function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isLoading } = useSelector((state) => state.loading);
  const token = Cookies.get('XSRF-TOKEN');
  const [darkMode, setDarkMode] = useState(localStorage.getItem("darkMode") === "true" ? true : false);

  useEffect(() => {
    const hasValidToken = () => {
      if (!token) {
        navigate("/auth");
      } else {
        dispatch(validateToken());
      }
    };
    hasValidToken();
  }, [token, navigate, dispatch]);


  const displayDarkMode = (active) => {
    setDarkMode(active);
    localStorage.setItem("darkMode", active);
  };

  return (
    <div className={`wrapper${darkMode ? " dark-mode": ""}`}>
      {isLoading && <Loading />}
      {token ? (
        <>
          <Header handleDarkMode={displayDarkMode} {...{ darkMode }} />
          <Sidebar />
          <div className="content-wrapper">
            <Routes />
          </div>
          <Footer />
        </>
      ) : (
        <Routes />
      )}
    </div>
  );
}

export default App;
