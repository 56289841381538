import React, { useState, useRef, useEffect } from "react";
import { Tooltip } from "react-tooltip";
export const DropdownItem = ({ label, icon, dropdownClass, tooltipId, tooltip, children }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClickInside = (e) => {
    e.stopPropagation();
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
};

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
}, []);

  return (
    <div className="btn-group" ref={dropdownRef}>
      <button
        className="btn btn-tool dropdown-toggle"
        onClick={toggleDropdown}
        data-tooltip-id={`tooltip-${tooltipId}`}
        data-tooltip-content={tooltip}
      >
        <i className={`fa fa-${icon}${label ? ' mr-1' : ''}`}></i>
        {label}
      </button>
      <Tooltip id={`tooltip-${tooltipId}`} />
      <div
        className={`dropdown-menu mt-2 ${
          isDropdownOpen ? "show" : ""
        } ${dropdownClass ?? ""}`}
        onClick={handleClickInside}
      >
        <div>{children}</div>
      </div>
    </div>
  );
};
