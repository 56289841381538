import React, { useState } from "react";
import Callout from "../../components/Callout";
import Grid from "../../common/layouts/Grid";

export const useCalloutReport = () => {
  const [reportInfo, setReportInfo] = useState({});

  function CalloutReport({ hideReportItems }) {
    return (
      <Callout
        title="Relatório"
        icon="info"
        id={reportInfo?.id}
        content={
          <>
            <Grid cols="12 4" classGrid=" invoice-col">
              <strong>Título</strong>
              <br />
              {reportInfo?.title}
            </Grid>
            <Grid cols="12 3" classGrid=" invoice-col">
              <strong>Subtítulo</strong>
              <br />
              {reportInfo?.subtitle}
            </Grid>
            <Grid cols="12 3" classGrid=" invoice-col">
              <strong>Endereço</strong>
              <br />
              {reportInfo?.address}
            </Grid>
            {!hideReportItems && (
              <Grid cols="12 2" classGrid=" invoice-col">
                <a href={`/items/${reportInfo?.id}/report`}>
                  <small className="float-right">Relatório de Vistoria</small>
                </a>
              </Grid>
            )}
          </>
        }
      />
    );
  }

  return {
    reportInfo,
    setReportInfo,
    CalloutReport
  }
}
