import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
/* Common / Layouts */
import ContentHeader from "@/common/layouts/ContentHeader";
import Content from "@/common/layouts/Content";
import Grid from "@/common/layouts/Grid";
import Row from "@/common/layouts/Row";
/* Common / Form */
import FormGroup from "@/common/form/FormGroup";
import Select from "@/common/form/controls/Select";
import { InputLabel, useInputControl } from "@/common/form/controls/Input";
/* Components */
import { Button } from "@/components/Button";
import Card from "@/components/Card";
/* Reducers */
import { show_item, create } from "@/reducers/Reports/ReportItemDescriptionsReducer";
import { useCalloutReport } from "@/pages/Reports/CalloutReport";

function DescriptionDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { report_item_id } = useParams();
  const { data } = useSelector((state) => state.reportItemDescriptions);
  const { errors, inputValidator, isFormValid } = useInputControl();
  const { CalloutReport, setReportInfo } = useCalloutReport();

  const initItemDescription = {
    description: "",
    type: "",
    report_item_id: report_item_id,
    images: [],
  };

  const [itemDescription, setItemDescription] = useState(initItemDescription);
  const [imagePreviews, setImagePreviews] = useState([]);
  const optonsType = ["Anterior", "Atual"];

  useEffect(() => {
    if (report_item_id) {
      dispatch(show_item({ id: report_item_id })).then(({ payload }) => setReportInfo(payload.report));
    }
    // eslint-disable-next-line
  }, [report_item_id]);

  const handleSubmit = () => {
    const formData = {
      ...itemDescription,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    dispatch(create(formData)).then(() => {
      navigate(`/descriptions/${report_item_id}/item`);
    });
  };

  useEffect(() => {
    const validator = [{name: "description", validators:[{ required: true }]}]
    inputValidator(itemDescription, validator);
    // eslint-disable-next-line
  }, [itemDescription]);

  return (
    <>
      <ContentHeader title="Incluir Descrição" previousRoute={`/descriptions/${report_item_id}/item`} />
      <Content>
        <CalloutReport />
        <Card
          title="Detalhes"
          iconTitle="list-alt"
          cardTools={
            <Button
              btnClass=" btn-tool"
              icon="save"
              disabled={!isFormValid}
              dataTooltipId="save-report-images"
              dataTooltipContent="Salvar"
              onClick={handleSubmit}
            />
          }
          cardBodyClass=" p-0"
        >
          <div className="pl-4 pr-4 pb-4">
            <Row>
              <Grid cols="12 10">
                <strong>Item:</strong>
                <div>{data?.report_item?.item}</div>
              </Grid>
              <Grid cols="12 2">
                <strong>Ordem:</strong>
                <div>{data?.report_item?.order}</div>
              </Grid>
            </Row>
            <hr />
            <Row>
              <Grid cols="12 12">
                <InputLabel
                  type="text"
                  name="description"
                  label="Descrição:"
                  value={itemDescription.description}
                  {...{ errors }}
                  onChange={(e) => {
                    const { value } = e.target;
                    setItemDescription({
                      ...itemDescription,
                      description: value,
                    });
                  }}
                />
              </Grid>
              <Grid cols="12 8">
                <FormGroup>
                  <label>Imagens:</label>
                  <input
                    id="images"
                    name="images"
                    type="file"
                    multiple
                    accept="image/*"
                    className="form-control"
                    onChange={(e) => {
                      const files = Array.from(e.target.files);
                      setItemDescription((prev) => ({
                        ...prev,
                        images: files,
                      }));
                      const previews = files.map((file) =>
                        URL.createObjectURL(file)
                      );
                      setImagePreviews(previews);
                    }}
                  />
                </FormGroup>
              </Grid>
              <Grid cols="12 4">
                <FormGroup>
                  <Select
                    name="type"
                    label="Tipo de imagem:"
                    hasFirstOptionEmpty
                    value={itemDescription.type || ""}
                    options={optonsType.map((type) => {
                      return { text: type, value: type.toLowerCase() };
                    })}
                    onChange={(e) => {
                      const { value } = e.target;
                      setItemDescription({ ...itemDescription, type: value });
                    }}
                  />
                </FormGroup>
              </Grid>
              {imagePreviews &&
                imagePreviews.map((imageSrc, index) => (
                  <Grid key={index} cols="12 3">
                    <img
                      src={imageSrc}
                      alt={`Imagem ${index}`}
                      className="img-fluid"
                    />
                  </Grid>
                ))}
            </Row>
          </div>
        </Card>
      </Content>
    </>
  );
}

export default DescriptionDetails;
