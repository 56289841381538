import React, { useState } from "react";
import MenuItem from "./MenuItem";

const MenuTree = ({ path, icon, label, childrens }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  return (
    <li className={`nav-item has-treeview ${ isOpen ? "menu-open" : ""}`}>
      <a href={path} className="nav-link" onClick={toggleMenu}>
        <i className={`nav-icon fas fa-${icon}`}></i>
        <p>
          {label}
          <i className="nav-icon right fas fa-angle-left"></i>
        </p>
      </a>
      <ul className="nav nav-treeview">
        {childrens
          .filter(({ visible }) => visible)
          .map(({ label, icon, path }, i) => (
            <MenuItem key={i} {...{ label, icon, path }} />
          ))}
      </ul>
    </li>
  );
};

export default MenuTree;
