import React from "react";
import Callout from "@/components/Callout";
import Grid from "@/common/layouts/Grid";

function CalloutInspection({ id, title, company }) {
  return (
    <Callout
      title="Vistoria"
      icon="info"
      {...{ id }}
      content={
        <>
          <Grid cols="12 5" classGrid=" invoice-col">
            <strong>Título</strong>
            <br />
            {title}
          </Grid>
          <Grid cols="12 5" classGrid=" invoice-col">
            <strong>Empresa</strong>
            <br />
            {company}
          </Grid>
          <Grid cols="12 2" classGrid=" invoice-col">
          <a href={`/visits/${id}/inspection`}><small className="float-right">Histórico de Visitas</small></a>
          </Grid>
        </>
      }
    />
  );
}

export default CalloutInspection;
