import { createSlice } from '@reduxjs/toolkit';
import { asyncApiThunkFactory } from '../asyncThunkFactory';

const initialState = {
  data: [],
  hasLoading: false,
  hasError: null,
};

export const dashboard = asyncApiThunkFactory('dashboard/index', `${process.env.REACT_APP_DASHBOARD_V1}`, 'get');

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(dashboard.pending, (state) => {
        state.hasLoading = true;
      })
      .addCase(dashboard.fulfilled, (state, action) => {
        state.hasLoading = false;
        state.data = action.payload;
      })
      .addCase(dashboard.rejected, (state, action) => {
        state.hasLoading = false;
        state.hasError = action.payload;
      });
  },
});

export default dashboardSlice.reducer;