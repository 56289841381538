import React from "react";
import BreadCrumb from "./BreadCrumb";
import Row from "./Row";
import Grid from "./Grid";

const ContentHeader = ({ title, subtitle, hideBreadcrumb, previousRoute }) => (
  <section className="content-header">
    <div className="container-fluid">
    {previousRoute && (
      <div className="mb-2">
          <a href={previousRoute}>
            <i className="fas fa-arrow-left mr-1"></i>
            Voltar
          </a>
      </div>
    )}  
      <Row rowClass=" my-1">
        <Grid cols="12 6">
        <h1 className="mb-0">{title}</h1>
        <small className="text-muted">{subtitle}</small>
        </Grid>
        <Grid cols="12 6">
        {!hideBreadcrumb && <BreadCrumb label={title} />}
        </Grid>
      </Row>
    </div>
  </section>
);

export default ContentHeader;
