import React from "react";

function Breadcrumb({ label }) {
  return (
      <ol className="breadcrumb float-sm-right">
        <li className="breadcrumb-item">
          <a href="/dashboard">Dashboard</a>
        </li>
        <li className="breadcrumb-item active">
          <span>{ label }</span>
        </li>
      </ol>
  );
}

export default Breadcrumb;
