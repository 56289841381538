import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
/* Commons / Layouts */
import ContentHeader from "@/common/layouts/ContentHeader";
import Content from "@/common/layouts/Content";
import Grid from "@/common/layouts/Grid";
import Row from "@/common/layouts/Row";
/* Commons / Form */
import FormGroup from "@/common/form/FormGroup";
import Select from "@/common/form/controls/Select";
/* Components */
import { Button } from "@/components/Button";
/* Reducers */
import { show } from "@/reducers/Reports/ReportItemDescriptionsReducer";
import { create } from "@/reducers/Reports/ReportItemDescriptionImagesReducer";
import Card from "@/components/Card";
import { useCalloutReport } from "@/pages/Reports/CalloutReport";

function DescriptionDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { data } = useSelector((state) => state.reportItemDescriptions);
  const { CalloutReport, setReportInfo } = useCalloutReport();

  const [imageItem, setImageItem] = useState({
    type: "",
    images: [],
  });
  const [imagePreviews, setImagePreviews] = useState([]);
  const optonsType = ["Anterior", "Atual"];

  useEffect(() => {
    if (id) {
      dispatch(show({ id })).then(({ payload }) =>
        setReportInfo(payload.report_item.report)
      );
    }
    // eslint-disable-next-line
  }, [id, dispatch]);

  useEffect(() => {
    setImageItem((prevItemDetails) =>
      Object.keys(prevItemDetails).reduce((acc, key) => {
        acc[key] = data[key];
        return { id, ...acc, report_item_id: data.report_item_id };
      }, {})
    );
  }, [data, id]);

  const handleSubmit = () => {
    const formData = {
      report_id: data.report_item.report_id,
      report_item_description_id: id,
      images: imageItem.images,
      type: imageItem.type,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    dispatch(create(formData)).then(() => {
      navigate(`/descriptions/${data.report_item_id}/item`);
    });
  };

  return (
    <>
      <ContentHeader
        title="Incluir Imagens de Descrição"
        previousRoute={`/descriptions/${data.report_item_id}/item`}
      />
      <Content>
        <CalloutReport />
        <Card
          title="Detalhes"
          iconTitle="list-alt"
          cardTools={
            <Button
              btnClass=" btn-tool"
              icon="save"
              disabled={!imageItem.images}
              dataTooltipId="save-report-images"
              dataTooltipContent="Salvar"
              onClick={handleSubmit}
            />
          }
          cardBodyClass=" p-0"
        >
          <div className="pl-4 pr-4 pb-4">
            <Row>
              <Grid cols="12 6">
                <strong>Item:</strong>
                <div>{data.report_item?.item}</div>
              </Grid>
              <Grid cols="12 6">
                <strong>Descrição:</strong>
                <div>{data.description}</div>
              </Grid>
            </Row>
            <hr />
            <Row>
              <Grid cols="12 8">
                <FormGroup>
                  <label>Imagens:</label>
                  <input
                    id="images"
                    name="images"
                    type="file"
                    multiple
                    accept="image/*"
                    className={`form-control${
                      !imageItem.images ? " is-invalid" : ""
                    }`}
                    onChange={(e) => {
                      const files = Array.from(e.target.files);
                      setImageItem((prev) => ({ ...prev, images: files }));
                      const previews = files.map((file) =>
                        URL.createObjectURL(file)
                      );
                      setImagePreviews(previews);
                    }}
                  />
                  {!imageItem.images && (
                    <div className="invalid-feedback">Campo obrigatório.</div>
                  )}
                </FormGroup>
              </Grid>
              <Grid cols="12 4">
                <FormGroup>
                  <Select
                    name="type"
                    label="Tipo de imagem:"
                    hasFirstOptionEmpty
                    value={imageItem.type || ""}
                    options={optonsType.map((type) => {
                      return { text: type, value: type.toLowerCase() };
                    })}
                    onChange={(e) => {
                      const { value } = e.target;
                      setImageItem({ ...imageItem, type: value });
                    }}
                  />
                </FormGroup>
              </Grid>
              {imagePreviews &&
                imagePreviews.map((imageSrc, index) => (
                  <Grid key={index} cols="12 3">
                    <img
                      src={imageSrc}
                      alt={`Imagem ${index}`}
                      className="img-fluid"
                    />
                  </Grid>
                ))}
            </Row>
          </div>
        </Card>
      </Content>
    </>
  );
}

export default DescriptionDetails;
