const inputsBombs = Array(6).fill().map((_, i) => { return `Bomba ${i + 1}\nCorrente nominal indicada na placa da bomba:\nR:\nS:\nT:\n\n` }).join('')
// const inputsBombs = [...Array(6).fill().map((_, i) => (
//   {
//     subtitle: `Bomba ${i + 1}`,
//     subchildren: [{
//       type: "text",
//       name: `cnBomba${i + 1}`,
//       label: "Corrente nominal indicada na placa da bomba:",
//       value: "",
//       cols: "12 6",
//     },
//     {
//       type: "text",
//       name: `bombaR${i + 1}`,
//       label: "R:",
//       value: "",
//       cols: "12 2",
//     },
//     {
//       type: "text",
//       name: `bombaS${i + 1}`,
//       value: "",
//       label: "S:",
//       cols: "12 2",
//     },
//     {
//       type: "text",
//       name: `bombaT${i + 1}`,
//       value: "",
//       label: "T:",
//       cols: "12 2",
//     }]
//   }
// ))]

export const formTestBombs = [
  { name: "t1", value: "", label: "01. O equipamento está instalado na base e com dispositivo anti vibratório." },
  { name: "t2", value: "", label: "02. Os equipamentos possuem união para manutenção." },
  { name: "t3", value: "", label: "03. Os equipamentos possuem na saída do recalque junta elástica." },
  { name: "t4", value: "", label: "04. Os equipamentos possuem na saída do recalque válvula de retenção." },
  { name: "t5", value: "", label: "05. Tubulações fixadas." },
  { name: "t6", value: "", label: "06. Tubulações pintadas e registros identificados." },
  { name: "t7", value: "", label: "07. O equipamento possui algum tipo de avaria" },
  { name: "t8", value: "", label: "08. A parte elétrica do equipamento já se encontra interligada, cabos com proteção de eletrodutos, partes vivas protegidas." },
  {
    name: "t9", type: "textarea", value: "RS =\nST =\nRT =", rows: "4", resize: "none", label: "09. Verificado os níveis de tensão se estão adequados com o projetado conforme prescrição da NBR5410 item 8.3.5. (entre todas as fases)."
  },
  // {
  //   name: "t9", value: "", title: "09. Verificado os níveis de tensão se estão adequados com o projetado conforme prescrição da NBR5410 item 8.3.5. (entre todas as fases).", children: [
  //     {
  //       type: "text",
  //       name: "RS",
  //       label: "RS:",
  //       value: "",
  //       cols: "12 4",
  //     },
  //     {
  //       type: "text",
  //       name: "ST",
  //       label: "ST:",
  //       value: "",
  //       cols: "12 4",
  //     },
  //     {
  //       type: "text",
  //       name: "RT",
  //       value: "",
  //       label: "RT:",
  //       cols: "12 4",
  //     }
  //   ]
  // },
  {
    name: "t10", type: "textarea", value: inputsBombs, rows: "6", resize: "none", label: "10. Medir corrente (em todas as fases) na partida e após partida.",
  },
  { name: "t11", value: "", label: "11. Corrente nominal medida compatível com a indicada na placa da bomba." },
  { name: "t12", value: "", label: "12. Ajustar os relés conforme as correntes nominais da bomba." },
  {
    name: "t13", type: "text", value: "", label: "Relés ajustados para:", cols: "12 3"
  },
  { name: "t14", value: "", label: "13. O sentido de rotação do motor está correta." },
  { name: "t15", value: "", label: "14. Testes dos equipamentos e de sinalizações de alarmes com as boias no quadro de comando nas situações:" },
  { name: "t16", value: "", label: "Automático" },
  { name: "t17", value: "", label: "Manual" },
  { name: "t18", value: "", label: "Reversão automática das bombas" },
  { name: "t19", value: "", label: "Alarme de defeito das bombas no quadro" },
  { name: "t20", value: "", label: "Alarme de defeito das bombas na portaria" },
  { name: "t21", value: "", label: "Alarme de extravazão reservatório superior no quadro" },
  { name: "t22", value: "", label: "Alarme de extravazão reservatório superior na portaria" },
  { name: "t23", value: "", label: "Apresentou algum vazamento" }
]