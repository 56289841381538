export const menus = [
    {

        items: [
            {
                label: "Relatórios",
                style: "treeview",
                icon: "file-alt",
                visible: true,
                order: 1,
                path: "#",
                childrens: [
                    {
                        label: "Incluir",
                        style: "link",
                        icon: "plus",
                        visible: true,
                        order: 1,
                        path: "/reports/create",
                    },
                    {
                        label: "Consultar",
                        style: "link",
                        icon: "search",
                        visible: true,
                        order: 1,
                        path: "/reports",
                    },
                ]
            },
            {
                label: "Vistorias",
                style: "treeview",
                icon: "building",
                visible: true,
                order: 1,
                path: "#",
                childrens: [
                    {
                        label: "Incluir",
                        style: "link",
                        icon: "plus",
                        visible: true,
                        order: 1,
                        path: "/inspections/create",
                    },
                    {
                        label: "Consultar",
                        style: "link",
                        icon: "search",
                        visible: true,
                        order: 1,
                        path: "/inspections",
                    },
                ]
            },
        ],

        //     items: [
        //         {
        //             label: "Relatórios",
        //             style: "treeview",
        //             icon: "copy",
        //             visible: true,
        //             order: 1,
        //             path: "#",
        //             childrens: [
        //                 {
        //                     label: "Incluir",
        //                     style: "link",
        //                     icon: "plus",
        //                     visible: true,
        //                     order: 1,
        //                     path: "/incluir_relatorio",
        //                 },
        //             ]
        //         },
        //     ],
        // }, {
        //     items: [
        //         {
        //             label: "Inspeções",
        //             style: "treeview",
        //             icon: "building",
        //             visible: true,
        //             order: 1,
        //             path: "#",
        //             childrens: [
        //                 {
        //                     label: "Incluir",
        //                     style: "link",
        //                     icon: "plus",
        //                     visible: true,
        //                     order: 1,
        //                     path: "/incluir_inspecao",
        //                 },
        //             ]
        //         },
        //     ],
    }
]