import React from "react";

function Select({ label, name, options, labelClass, selectClass, value, onChange, disabled, hasFirstOptionEmpty, emptyText }) {
  return (
    <>
        <label className={labelClass}>{label}</label>
      <select className={`form-control${selectClass ?? ""}`} {...{ name, value, onChange, disabled }} >
        {hasFirstOptionEmpty && (
            <option value="">{emptyText ?? "-- Selecione --"}</option>
        )}
        {options.map(({ text, value }, idx) => (
            <option key={idx} {...{ value }}>{text}</option>
        ))}
      </select>
    </>
  );
}

export default Select;
