import { createSlice } from '@reduxjs/toolkit';
import { asyncApiThunkFactory } from '../asyncThunkFactory';

const initialState = {
  data: {},
  totalItems: 0,
  hasLoading: false,
  hasError: null
};

const handlePending = (state) => {
  state.hasLoading = true;
};

const handleReject = (state, action) => {
  state.hasLoading = false;
  state.hasError = action.payload;
};

const handleFulfilledGet = (state, action) => {
  state.hasLoading = false;
  state.data = action.payload;
};

const handleFulfilledSubmit = (state) => {
  state.hasLoading = false;
  state.data = null;
};


export const index = asyncApiThunkFactory('report_item_descriptions/index', `${process.env.REACT_APP_REPORT_ITEM_DESCRIPTIONS_V1}`, 'get');
export const show = asyncApiThunkFactory('report_item_descriptions/show', `${process.env.REACT_APP_REPORT_ITEM_DESCRIPTIONS_V1}`, 'get');
export const show_item = asyncApiThunkFactory('report_item_descriptions/show_item', `${process.env.REACT_APP_REPORT_ITEM_DESCRIPTIONS_V1}/item`, 'get');
export const create = asyncApiThunkFactory('report_item_descriptions/create', `${process.env.REACT_APP_REPORT_ITEM_DESCRIPTIONS_V1}`, 'post');
export const update = asyncApiThunkFactory('report_item_descriptions/update', `${process.env.REACT_APP_REPORT_ITEM_DESCRIPTIONS_V1}`, 'put');
export const remove = asyncApiThunkFactory('report_item_descriptions/remove', `${process.env.REACT_APP_REPORT_ITEM_DESCRIPTIONS_V1}`, 'delete');

const reportItemDescriptionsSlice = createSlice({
  name: 'report_item_descriptions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // index
      .addCase(index.pending, handlePending)
      .addCase(index.fulfilled, handleFulfilledGet)
      .addCase(index.rejected, handleReject)
      // show
      .addCase(show.pending, handlePending)
      .addCase(show.fulfilled, handleFulfilledGet)
      .addCase(show.rejected, handleReject)
      // show_item
      .addCase(show_item.pending, handlePending)
      .addCase(show_item.fulfilled, handleFulfilledGet)
      .addCase(show_item.rejected, handleReject)
      // create
      .addCase(create.pending, handlePending)
      .addCase(create.fulfilled, handleFulfilledSubmit)
      .addCase(create.rejected, handleReject)
      // update
      .addCase(update.pending, handlePending)
      .addCase(update.fulfilled, handleFulfilledSubmit)
      .addCase(update.rejected, handleReject)
      // remove
      .addCase(remove.pending, handlePending)
      .addCase(remove.fulfilled, handleFulfilledSubmit)
      .addCase(remove.rejected, handleReject)
  },

});
export default reportItemDescriptionsSlice.reducer;