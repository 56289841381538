import { createSlice } from '@reduxjs/toolkit';
import { asyncApiThunkFactory, handlePending, handleReject, handleFulfilled, handleFulfilledSubmit } from '../asyncThunkFactory';

const initialState = {
  data: {},
  hasLoading: false,
  hasError: null
};

export const visit = asyncApiThunkFactory('inspection_visit_images/visit', `${process.env.REACT_APP_INSPECTION_VISIT_IMAGES_V1}/visit`, 'get');
export const create = asyncApiThunkFactory('inspection_visit_images/create', `${process.env.REACT_APP_INSPECTION_VISIT_IMAGES_V1}`, 'post');
export const remove = asyncApiThunkFactory('inspection_visit_images/remove', `${process.env.REACT_APP_INSPECTION_VISIT_IMAGES_V1}/remove_images`, 'post');

const inspectionVisitImagesSlice = createSlice({
  name: 'inspection_visit_images',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // visit
      .addCase(visit.pending, handlePending)
      .addCase(visit.fulfilled, handleFulfilled)
      .addCase(visit.rejected, handleReject)
      // create
      .addCase(create.pending, handlePending)
      .addCase(create.fulfilled, handleFulfilledSubmit)
      .addCase(create.rejected, handleReject)
      // remove
      .addCase(remove.pending, handlePending)
      .addCase(remove.fulfilled, handleFulfilledSubmit)
      .addCase(remove.rejected, handleReject)
  },
});

export default inspectionVisitImagesSlice.reducer;