import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./style.css";
/* Common */
import { InputGroupAppend } from "@/common/form/controls/Input";
import FormGroup from "@/common/form/FormGroup";
/* Layout */
import Grid from "@/common/layouts/Grid";
/* Reducers */
import { login } from "@/reducers/Auth/AuthReducer";

function Auth() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoading, hasAuth } = useSelector((state) => state.auth);

  const [formAuth, setFormAuth] = useState({
    email: "",
    password: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormAuth({
      ...formAuth,
      [name]: value,
    });
  };

  const [inputPassw, setInputPassw] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const { email, password } = formAuth;
    dispatch(login({ email, password }));
  };

  useEffect(() => {
    if (hasAuth) navigate("/dashboard");
  }, [hasAuth, navigate]);


  function formIsValid(){
    return formAuth.email && formAuth.password
  }

  return (
    <div className="login-wrapper">
      <Grid cols="12 8" classGrid=" px-0 d-none d-sm-block">
        <div className="wallpaper"></div>
      </Grid>
      <Grid cols="12 4" classGrid=" login-page">
        <div className="login-box">
          <div className="login-card-body rounded elevation-3">
            <div className="login-logo">
              <a href="/" className="nav-link">
                My<strong>LinceEng</strong>
              </a>
            </div>
            <form onSubmit={handleSubmit}>
              <FormGroup>
                <InputGroupAppend
                  type="email"
                  name="email"
                  placeholder="E-mail"
                  icon="envelope"
                  onChange={handleInputChange}
                />
              </FormGroup>
              <FormGroup>
                <InputGroupAppend
                  type={inputPassw ? "text" : "password"}
                  name="password"
                  placeholder="Senha"
                  icon={inputPassw ? "eye" : "eye-slash"}
                  onChange={handleInputChange}
                  onClickSpan={() => setInputPassw(!inputPassw)}
                  cursor
                />
              </FormGroup>
              <button
                className="btn btn-lg btn-primary btn-block my-4"
                disabled={isLoading || !formIsValid()}
              >
                {isLoading ? (
                  <span>
                    <i className="fas fa-spinner fa-spin mr-2"></i>
                    Acessando...
                  </span>
                ) : (
                  <span>Entrar</span>
                )}
              </button>
            </form>
          </div>
          <div className="footer-login">
            <small className="bottom text-muted">
              Developed by Max Fernandes de Souza. All rights reserved.
            </small>
          </div>
        </div>
      </Grid>
    </div>
  );
}

export default Auth;
