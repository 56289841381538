import React from "react";
import { useSelector } from "react-redux";
import LoadingGif from "@/assets/loading.gif";
import "./style.css";

function Loading() {
  const { text } = useSelector((state) => state.loading);

  return (
    <div className="loading">
      <img src={LoadingGif} alt="Loading" />
      <div className="my-2 text-bold">{text ?? "Carregando..."}</div>
    </div>
  );
}

export default Loading;
