import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
/* Common / Form */
import { FormControl as FormInspection } from "@/common/form/FormControl";
import { useInputControl } from "@/common/form/controls/Input";
/* Common / Layouts */
import Content from "@/common/layouts/Content";
import ContentHeader from "@/common/layouts/ContentHeader";
/* Components */
import { Button } from "@/components/Button";
import { ButtonGroup } from "@/components/ButtonGroup";
import Card from "@/components/Card";
import {
  show,
  create,
  update,
} from "@/reducers/Inspections/InspectionsReducer";

function DetailsInspection() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { errors, inputValidator, isFormValid } = useInputControl();
  const [inspection, setInspection] = useState({});

  const initInspection = {
    title: "",
    company: "",
    observation: "",
    status: "Em andamento",
  };

  useEffect(() => {
    if (id)
      dispatch(show({ id })).then(({ payload }) => setInspection(payload));
    else setInspection(initInspection);
    // eslint-disable-next-line
  }, [id]);

  const inputsForm = [
    {
      label: "Título:",
      name: "title",
      type: "text",
      value: inspection.title,
      cols: "12 12",
      validators: [{ required: true }],
    },
    {
      label: "Empresa:",
      name: "company",
      type: "text",
      value: inspection.company,
      validators: [{ required: true }],
      cols: "12 12",
    },
    {
      label: "Observação:",
      name: "observation",
      type: "textarea",
      rows: 6,
      resize: "none",
      value: inspection.observation ?? "",
      cols: "12 12",
    },
    {
      label: "Status:",
      name: "status",
      type: "radio",
      value: inspection.status,
      options: [
        { label: "Em andamento", value: "Em andamento" },
        { label: "Concluído", value: "Concluída" },
      ],
      cols: "12 12",
      hide: !id ?? false,
    },
  ]

  const handleInspection = (e) => {
    const { name, value } = e.target;
    setInspection({ ...inspection, [name]: value });
  };

  const submit = () => {
    dispatch(id ? update(inspection) : create(inspection)).then(() =>
      navigate("/inspections")
    );
  };

  useEffect(() => {
    inputValidator(inspection, inputsForm);
    // eslint-disable-next-line
  }, [inspection, inputValidator]);

  return (
    <>
      <ContentHeader
        title={`${id ? "Editar" : "Incluir"} Vistoria`}
        previousRoute="/inspections"
      />
      <Content>
        <Card
          title="Detalhes"
          iconTitle="list-alt"
          cardTools={
            <ButtonGroup>
              <Button
                icon="undo"
                btnClass=" btn-tool"
                onClick={() => setInspection(initInspection)}
                dataTooltipId="inspection-reset"
                dataTooltipContent="Redefinir"
              />
              <Button
                icon="save"
                btnClass=" btn-tool"
                disabled={!isFormValid}
                onClick={submit}
                dataTooltipId="inspection-save"
                dataTooltipContent="Salvar"
              />
            </ButtonGroup>
          }
          cardBodyClass=" p-0"
        >
          <div className="pl-4 pr-4 pb-4">
            <FormInspection
              {...{
                inputsForm,
                errors,
              }}
              handleInputForm={handleInspection}
            />
          </div>
        </Card>
      </Content>
    </>
  );
}

export default DetailsInspection;
