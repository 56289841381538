import React from 'react';
import Content from '../../common/layouts/Content';

function NotFound() {
  return (
    <Content contentClass=' p-3'> 
      <h3>
        <strong>Erro 404</strong>
      </h3>
      <h1>
        <strong>Ops,</strong>
        <br />
        Página não encontrada!
      </h1>
      <hr />
      <p>
        <a href='/dashboard'>Ir ao Dashboard</a>
      </p>
    </Content>
  );
}

export default NotFound;
