import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Content from "@/common/layouts/Content";
import ContentHeader from "@/common/layouts/ContentHeader";
import Grid from "@/common/layouts/Grid";
import Row from "@/common/layouts/Row";
import Box from "@/common/layouts/Box";
import { dashboard } from "@/reducers/Dashboard/DashboardReducer";

function Dashboard() {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.dashboard);

  useEffect(() => {
    dispatch(dashboard());
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ContentHeader title="Dashboard" subtitle="Bem vindo(a)!" hideBreadcrumb />
      <Content>
        {data.boxes && (
          <Row>
            {data.boxes.map(({ label, icon, routeList, routeAdd,  childrens, totalItems }, idx) => (
              <Grid cols="12 6" key={idx} classGrid=" mb-4">
                <Box {...{ label, icon, routeList, routeAdd, childrens, totalItems }} />
              </Grid>
            ))}
          </Row>
        )}

        {/* <Row>
            {hubs.map(({ color, icon, label, path }, idx) => (
              <Grid cols="12 4" key={idx}>
                <Hub {...{ icon, color, label, path }} />
              </Grid>
            ))}
          </Row> */}
      </Content>
    </>
  );
}

export default Dashboard;
