import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
/* Common / Form */
import { FormControl as FormVisit } from "@/common/form/FormControl";
import FormGroup from "@/common/form/FormGroup";
import { useInputControl } from "@/common/form/controls/Input";
/* Common / Layouts */
import ContentHeader from "@/common/layouts/ContentHeader";
import Content from "@/common/layouts/Content";
import Row from "@/common/layouts/Row";
import Grid from "@/common/layouts/Grid";
/* Components */
import Card from "@/components/Card";
import { ButtonGroup } from "@/components/ButtonGroup";
import { Button } from "@/components/Button";
/* Reducers */
import {
  show as showVisit,
  create,
  update,
} from "@/reducers/Inspections/InspectionVisitsReducer";
import { show as showInspection } from "@/reducers/Inspections/InspectionsReducer";

import CalloutInspection from "../../CalloutInspection";

function DetailsVisit() {
  const { inspection_id, id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: inspectionItem } = useSelector((state) => state.inspections);
  const { data: itemVisit } = useSelector((state) => state.inspectionVisits);
  const { inputValidator, errors, isFormValid, imagePreviews, setImagePreviews } = useInputControl();

  const initVisit = useMemo(
    () => ({
      date_of_visit: new Date().toISOString().split("T")[0],
      item: null,
      responsible: "",
      description: "",
      status: "Pendente",
      inspection_id,
    }),
    [inspection_id]
  );

  const [visit, setVisit] = useState({});

  useEffect(() => {
    dispatch(showInspection({ id: inspection_id }));
    if (id) dispatch(showVisit({ id })).then(({ payload }) => setVisit(payload));
    else setVisit(initVisit);
    // eslint-disable-next-line
  }, [id, inspection_id]);

  useEffect(() => {
    setVisit((prevs) =>
      id
        ? Object.keys(prevs).reduce((acc, key) => {
            acc[key] = itemVisit[key];
            return { id, ...acc };
          }, {})
        : prevs
    );
  }, [id, itemVisit]);

  const inputsForm = [
    {
      label: "Data da visita:",
      name: "date_of_visit",
      type: "date",
      value: visit.date_of_visit,
      cols: "12 6",
      validators: [{ required: true }],
    },
    {
      label: "Status:",
      name: "status",
      type: "select",
      value: visit.status,
      options: [
        { text: "As-Built", value: "As-Built" },
        { text: "Concluído", value: "Concluído" },
        { text: "Em andamento", value: "Em andamento" },
        { text: "Finalizado", value: "Finalizado" },
        { text: "Informativo", value: "Informativo" },
        {
          text: "Informativo/Acompanhamento",
          value: "Informativo/Acompanhamento",
        },
        { text: "Observação", value: "Observação" },
        { text: "Pendente", value: "Pendente" },
      ],
      cols: "12 6",
    },
    {
      label: "Item Nº:",
      name: "item",
      type: "number",
      value: visit.item,
      cols: "12 6",
      validators: [{ required: true }],
    },
    {
      label: "Responsável:",
      name: "responsible",
      type: "text",
      value: visit.responsible,
      cols: "12 6",
      validators: [{ required: true }],
    },
    {
      label: "Descrição:",
      name: "description",
      type: "textarea",
      rows: 6,
      resize: "none",
      value: visit.description,
      cols: "12 12",
      validators: [{ required: true }],
    },
  ];

  useEffect(() => {
    inputValidator(visit, inputsForm)
    // eslint-disable-next-line
  },[visit])

  const handleVisit = (e) => {
    const { name, value } = e.target;
    setVisit((prevs) => ({ ...prevs, [name]: value }));
  };

  const submit = () => {
    const formData = {
      ...visit,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    dispatch(id ? update(visit) : create(formData)).then(() =>
      navigate(`/visits/${inspection_id}/inspection`)
    );
  };

  return (
    <>
      <ContentHeader title={`${id ? "Editar" : "Incluir"} Visita`} previousRoute={`/visits/${inspection_id}/inspection`} />
      <Content>
        <CalloutInspection
          id={inspection_id}
          title={inspectionItem?.title}
          company={inspectionItem?.company}
        />
        <Card
          title="Detalhes"
          iconTitle="list-alt"
          cardTools={
            <ButtonGroup>
              <Button
                icon="undo"
                btnClass=" btn-tool"
                onClick={() => setVisit(initVisit)}
                dataTooltipId="visit-reset"
                dataTooltipContent="Redefinir"
              />
              <Button
                icon="save"
                btnClass=" btn-tool"
                disabled={!isFormValid}
                onClick={() => submit()}
                dataTooltipId="visit-save"
                dataTooltipContent="Salvar"
              />
            </ButtonGroup>
          }
          cardBodyClass=" p-0"
        >
          <div className="pl-4 pr-4 pb-4">
            <FormVisit
              {...{
                inputsForm,
                errors
              }}
              handleInputForm={handleVisit}
            />
            <Row>
              <Grid cols="12 12">
                <FormGroup>
                  <label>Imagens:</label>
                  <input
                    id="images"
                    name="images"
                    type="file"
                    multiple
                    accept="image/*"
                    className="form-control"
                    onChange={(e) => {
                      const files = Array.from(e.target.files);
                      setVisit((prevs) => ({ ...prevs, images: files }));
                      const previews = files.map((file) =>
                        URL.createObjectURL(file)
                      );
                      setImagePreviews(previews);
                    }}
                  />
                </FormGroup>
              </Grid>
              {imagePreviews &&
                imagePreviews.map((imageSrc, index) => (
                  <Grid key={index} cols="12 3">
                    <img
                      src={imageSrc}
                      alt={`Imagem ${index}`}
                      className="img-fluid"
                    />
                  </Grid>
                ))}
            </Row>
          </div>
        </Card>
      </Content>
    </>
  );
}

export default DetailsVisit;
