import { useEffect, useState } from "react";
import { formTestBombs } from "../Tests/Bombs";

const inputsTagModelSerie = [
    { type: "text", name: "tag", label: "Tag:", value: "", cols: "12 4" },
    { type: "text", name: "model", label: "Modelo:", value: "", cols: "12 4" },
    { type: "text", name: "serie", label: "Nº Serie:", value: "", cols: "12 4" },
]

export function useTestFunctionality() {
    const [inputsForm, setInputsForm] = useState([]);
    const [testFunctionality, setTestFunctionality] = useState({});

    useEffect(() => {
        formFunctionalityTest.filter(({ item }) => item === testFunctionality.item).map(({ form }) => setInputsForm(form || []));
    }, [testFunctionality])

    useEffect(() => {
        setTestFunctionality((prevs) => {
            const { item, report_id, description, images } = prevs;
            return inputsForm.length
                ? inputsForm.reduce((acc, curr) => {
                    acc[curr.name] = curr.value;
                    return { acc, ...prevs };
                })
                : { item, report_id, description, images }

        });
    }, [inputsForm]);

    const handleInputTestFunctionality = (e) => {
        const { name, value } = e.target;
        setTestFunctionality({...testFunctionality, [name]: value})
      };

    return {
        inputsForm,
        setInputsForm,
        testFunctionality,
        setTestFunctionality,
        handleInputTestFunctionality
    }
}

export const formFunctionalityTest = [
    { item: "Bombas", form: [...inputsTagModelSerie, ...formTestBombs] },
    { item: "Bombas de Incêndio", form: [] },
    { item: "Gerador", form: [] },
    { item: "Piscina e Espelho d'água", form: [] },
    { item: "Pressurizador de água", form: [] },
    { item: "Sistema de detecção, prevenção de incêndio", form: [] },
    { item: "Sistema de tratamento de água pluvial", form: [] },
    { item: "Sistema central de água quente", form: [] },
]