import React from "react";

const Footer = ({ darkMode }) => (
  <footer className={`main-footer ${darkMode ? "text-white" : ""}`}>
    <strong>
      Copyright &copy; 2024
      <a href="https://www.linceeng.com/" target="_blank" rel="noreferrer">
        &ensp;LinceEng
      </a>
    </strong>
  </footer>
);

export default Footer;
