import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
/* Common / Layouts */
import Content from "@/common/layouts/Content";
import ContentHeader from "@/common/layouts/ContentHeader";
/* Common / Form */
import {
  InputCheckbox,
  InputGroupAppend,
} from "@/common/form/controls/Input";
import Select from "@/common/form/controls/Select";
/* Components */
import { DropdownItem } from "@/components/DropdownItem";
import Card from "@/components/Card";
import { ButtonGroup } from "@/components/ButtonGroup";
import { Button } from "@/components/Button";
/* Components / Table */
import { Table } from "@/components/Table/Table";
import { useTableFilterSearch } from "@/components/Table/TableFilterSearch";
/* Components / Modal */
import { Modal as ModalDeleteReport } from "@/components/Modal/Modal";
import { useModal } from "@/components/Modal/ModalStore";
/* Reducers */
import { index, remove, export_word } from "@/reducers/Reports/ReportsReducer";

function Reports() {
  const dispatch = useDispatch();
  const { data: reports } = useSelector((state) => state.reports);
  const {
    filterSearch,
    handleTableFilterSearch,
    setFilterSearch,
    handleInputFilterSearch,
  } = useTableFilterSearch();
  const { modalData, setModalData } = useModal();

  useEffect(() => {
    onFilterSearch();
    // eslint-disable-next-line
  }, [filterSearch.params.status, filterSearch.params.currentPage, filterSearch.params.itemsPerPage]);


  const onFilterSearch = () => {
    const filterSearchURL = {
      params: {
        ...filterSearch.params,
        fields: filterSearch.params.fields ?? ["title", "subtitle", "address"].join("?"),
      }
    }
    setFilterSearch(filterSearchURL);
    dispatch(index(filterSearchURL));
  };
  
  const radioStatusReports = ["Em andamento", "Concluído"];

  const fields = [
    { key: "title", label: "Título", isFiltered: true },
    {
      key: "subtitle",
      label: "Subtítulo",
      thClass: "d-none d-md-table-cell",
      tdClass: "d-none d-md-table-cell",
      isFiltered: true,
    },
    {
      key: "address",
      label: "Endereço",
      isFiltered: true,
    },
  ];

  const TableReports = () => {
    const RowsReports = () => {
      const [expandedRow, setExpandedRow] = useState(null);
      const toggleRow = (id) => {
        setExpandedRow(expandedRow === id ? null : id);
      };

      const handleExportWord = (id, title) => {
        const currentDate = `${new Date()
          .getDate()
          .toString()
          .padStart(2, "0")}${(new Date().getMonth() + 1)
          .toString()
          .padStart(2, "0")}${new Date().getFullYear()}`;
        const formData = {
          params: {
            id,
            filename: `RELATORIO_${title
              .replace(/\s+/g, "_")
              .toUpperCase()}_${currentDate}.docx`,
          },
          responseType: "blob",
        };
        dispatch(export_word(formData));
      };

      return reports.data.map((row, idx) => (
        <React.Fragment key={idx}>
          <tr>
            {fields.map(({ key, tdClass }, idx) => (
              <td key={idx} className={tdClass}>
                {row[key]}
              </td>
            ))}
            <td>
              <ButtonGroup>
                <a
                  href={`/reports/${row.id}`}
                  className="btn btn-tool"
                  data-tooltip-id="tooltip-report-edit"
                  data-tooltip-content="Editar"
                >
                  <i className="fas fa-pen mr-2"></i>
                </a>
                <Tooltip id="tooltip-report-edit" />
                <Button
                  btnClass=" btn-tool"
                  icon="trash-alt"
                  dataTooltipId="report-delete"
                  dataTooltipContent="Apagar"
                  onClick={() =>
                    setModalData({
                      ...modalData,
                      modalShow: !modalData.modalShow,
                      modalContent: row,
                    })
                  }
                />
                <Button
                  btnClass=" btn-tool"
                  icon="ellipsis-v"
                  onClick={() => toggleRow(row.id)}
                  dataTooltipId={`report_${row.id}-actions`}
                  dataTooltipContent="Ações"
                />
              </ButtonGroup>
              {/* <i
                  className={`fa fa-fw ${
                    expandedRow === row.id ? "fa-caret-up" : "fa-caret-down"
                  }`}
                ></i> */}
            </td>
          </tr>
          {expandedRow === row.id && (
            <tr className="collapse show bg-transparent">
              <td className="p-3 border" colSpan="4">
                <ul className="p-1">
                  <li className="nav-link">
                    <a href={`/items/${row.id}/report`}>
                      <i className="fas fa-file-alt mr-2"></i>Relatório de
                      Vistoria
                    </a>
                  </li>
                  <li className="nav-link">
                    <a href={`/functionality_tests/${row.id}/report`}>
                      <i className="fas fa-wrench mr-2"></i>Teste de
                      Funcionalidade
                    </a>
                  </li>
                  <li className="nav-link">
                    <Link onClick={() => handleExportWord(row.id, row.title)}>
                      <i className="fas fa-file-word mr-2"></i>Exportar Word
                    </Link>
                  </li>
                </ul>
              </td>
            </tr>
          )}
        </React.Fragment>
      ));
    };

    return (
      <Table
        {...{ fields }}
        tableClass=" table-striped table-valign-middle"
        rows={<RowsReports />}
        HasPagination
        paginationClass=" justify-content-center"
        totalItems={reports?.total}
        itemsPerPage={filterSearch.params.itemsPerPage}
        currentPage={filterSearch.params.currentPage}
        onItemsPerPageChange={(key, value) =>
          handleTableFilterSearch(key, value)
        }
        onPageChange={(currentPage) =>
          handleTableFilterSearch("currentPage", currentPage)
        }
      />
    );
  };

  return (
    <>
      <ContentHeader
        title="Relatórios"
        subtitle={`${
          filterSearch.params.status ? `${filterSearch.params.status} | ` : ""
        } Total: ${reports?.total ?? 0}`}
      />
      <Content>
        <Card
          cardTitle={
            <div className="card-title">
              <a
                href="/reports/create"
                className="btn btn-tool btn-sm"
                data-tooltip-id="tooltip-report-create"
                data-tooltip-content="Incluir"
              >
                <i className="fas fa-plus"></i>
              </a>
              <Tooltip id="tooltip-report-create" />
              <DropdownItem
                icon="filter"
                tooltip="Filtro"
                tooltipId="filter-reports"
                dropdownClass=" p-3 dropdown-menu-lg"
              >
                <>
                  <Select
                    label="Status:"
                    name="status"
                    selectClass=" form-control-sm"
                    hasFirstOptionEmpty
                    emptyText="Mostrar todos"
                    options={radioStatusReports.map((status) => {
                      return { text: status, value: status };
                    })}
                    value={filterSearch.params.status}
                    onChange={handleInputFilterSearch}
                  />
                  <div className="dropdown-divider my-3"></div>
                  <label>Filtrar por:</label>
                  {fields
                    .filter(({ isFiltered }) => isFiltered)
                    .map(({ key, label }, idx) => (
                      <InputCheckbox
                        name="fields"
                        key={idx}
                        {...{ label, value: key }}
                        checked={
                          filterSearch.params.fields
                            ? filterSearch.params.fields
                                .split("?")
                                .some((field) => field === key)
                            : false
                        }
                        onChange={handleInputFilterSearch}
                      />
                    ))}
                </>
              </DropdownItem>
            </div>
          }
          cardTools={
            <div className="my-2">
              <InputGroupAppend
                type="search"
                name="search"
                icon="search"
                value={filterSearch.params.search}
                onChange={handleInputFilterSearch}
                cursor
                placeholder="Procurar..."
                inputClass=" form-control-sm"
                dataTooltipId="search-reports"
                dataTooltipContent="Pesquisar"
                onClickSpan={onFilterSearch}
              />
            </div>
          }
          cardBodyClass=" table-responsive p-0"
        >
          <TableReports />
        </Card>
        <ModalDeleteReport
          modalId="report-delete"
          modalShow={modalData.modalShow}
          modalTitle="Excluir Relatório"
          modalBody={
            modalData.modalContent && (
              <>
                <p>Tem certeza que deseja excluir esse relatório?</p>
                <dl className="row">
                  <dt className="col-sm-2">Título:</dt>
                  <dd className="col-sm-10">{modalData.modalContent.title}</dd>
                  <dt className="col-sm-2">Subtítulo:</dt>
                  <dd className="col-sm-10">
                    {modalData.modalContent.subtitle}
                  </dd>
                  <dt className="col-sm-2">Endereço:</dt>
                  <dd className="col-sm-10">
                    {modalData.modalContent.address}
                  </dd>
                </dl>
              </>
            )
          }
          hideModal={() => setModalData(false)}
          modalFooterClass=" d-flex justify-content-end"
          modalActions={
            <Button
              btnClass=" btn-primary"
              icon="check"
              label="Confirmar"
              onClick={() => {
                setModalData(false);
                dispatch(remove({ id: modalData.modalContent.id })).then(() =>
                  onFilterSearch()
                );
              }}
            />
          }
        />
      </Content>
    </>
  );
}

export default Reports;
