import React from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";

function LinkTooltip({ path, icon, title, linkClass, dataTooltipId, dataTooltipContent }) {
  return (
    <>
      <Link to={path} className={linkClass} data-tooltip-id={`tooltip-${dataTooltipId}`} data-tooltip-content={dataTooltipContent}>
        <i className={`fas fa-${icon}`}></i>
        {title}
      </Link>
      <Tooltip id={`tooltip-${dataTooltipId}`} />
    </>
  );
}

export default LinkTooltip;
