import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
/* Common */
import ContentHeader from "@/common/layouts/ContentHeader";
import Content from "@/common/layouts/Content";
import Card from "@/components/Card";
import Row from "@/common/layouts/Row";
import Grid from "@/common/layouts/Grid";
import Select from "@/common/form/controls/Select";
import {
  InputGroupAppend,
  InputLabel,
} from "@/common/form/controls/Input";
import FormGroup from "@/common/form/FormGroup";
/* Components */
import { Table } from "@/components/Table/Table";
import { ButtonGroup } from "@/components/ButtonGroup";
import { Button } from "@/components/Button";
import { useTableFilterSearch } from "@/components/Table/TableFilterSearch";
import { Modal as ModalDeleteReportItem } from "@/components/Modal/Modal";
import { useModal } from "@/components/Modal/ModalStore";
/* Reducers */
import {
  create,
  index,
  remove,
  update,
} from "@/reducers/Reports/ReportItemsReducer";
import { useCalloutReport } from "../CalloutReport";

function InspectionReport() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { data } = useSelector((state) => state.reportItems);
  const { report, report_items, total_items } = data;
  const initReportItem = {
    item: "",
    order: 1,
  };
  const [reportItem, setReportItem] = useState(initReportItem);

  const { filterSearch, setFilterSearch, handleTableFilterSearch } =
    useTableFilterSearch();
  const { modalData, setModalData } = useModal();
  const { CalloutReport, setReportInfo } = useCalloutReport();

  useEffect(() => {
    onFilterSearch();
    // eslint-disable-next-line
  }, [filterSearch.params.currentPage, filterSearch.params.itemsPerPage]);

  const onFilterSearch = () => {
    const filterSearchURL = {
      params: {
        ...filterSearch.params,
        report_id: id,
        fields: "item",
      },
    };
    setFilterSearch(filterSearchURL);
    dispatch(index(filterSearchURL)).then(({ payload }) => setReportInfo(payload.report));
  };

  useEffect(() => {
    setReportItem({ ...reportItem, order: total_items + 1 });
    // eslint-disable-next-line
  }, [report]);

  const TableReportItems = () => {
    const fields = [
      { key: "item", label: "Item", thClass: "w-75" },
      {
        key: "order",
        label: "Ordem",
        thClass: "text-center",
        tdClass: "text-center",
      },
    ];

    const RowsReportItems = () => {
      const [expandedRow, setExpandedRow] = useState(null);
      const toggleRow = (id) => {
        setExpandedRow(expandedRow === id ? null : id);
      };

      return report_items.data.map((row, idx) => (
        <React.Fragment key={idx}>
          <tr>
            {fields.map(({ key, tdClass }, idx) => (
              <td key={idx} className={tdClass}>
                {row[key]}
              </td>
            ))}
            <td>
              <ButtonGroup>
                <Button
                  btnClass=" btn-tool"
                  icon="pencil-alt"
                  onClick={() => setReportItem(row)}
                  dataTooltipId="tooltip-items-edit"
                  dataTooltipContent="Editar"
                />
                <Button
                  btnClass=" btn-tool"
                  icon="trash-alt"
                  onClick={() =>
                    setModalData({
                      ...modalData,
                      modalShow: !modalData.modalShow,
                      modalContent: row,
                    })
                  }
                  dataTooltipId="tooltip-items-remove"
                  dataTooltipContent="Remover"
                />
                <Button
                  btnClass=" btn-tool"
                  icon="ellipsis-v"
                  onClick={() => toggleRow(row.id)}
                  dataTooltipId={`tooltip-items-actions_${row.id}}`}
                  dataTooltipContent="Ações"
                />
              </ButtonGroup>
            </td>
          </tr>
          {expandedRow === row.id && (
            <tr className="collapse show bg-white">
              <td className="p-3 border" colSpan="4">
                <ul className="p-1">
                  <li className="nav-link">
                    <a href={`/item/${row.id}/description/create`}>
                      <i className="fas fa-plus mr-2"></i>Incluir Descrição
                    </a>
                  </li>
                  <li className="nav-link">
                    <a href={`/descriptions/${row.id}/item`}>
                      <i className="fas fa-file-alt mr-2"></i>Descrições do Item
                    </a>
                  </li>
                </ul>
              </td>
            </tr>
          )}
        </React.Fragment>
      ));
    };

    return (
      <Table
        {...{ fields }}
        rows={<RowsReportItems />}
        HasPagination
        paginationClass=" justify-content-center"
        totalItems={total_items}
        itemsPerPage={filterSearch.params.itemsPerPage}
        currentPage={filterSearch.params.currentPage}
        onItemsPerPageChange={(key, value) =>
          handleTableFilterSearch(key, value)
        }
        onPageChange={(currentPage) =>
          handleTableFilterSearch("currentPage", currentPage)
        }
      />
    );
  };

  const submit = (method, itemId) => {
    return new Promise((resolve) => {
      switch (method) {
        case "update":
          resolve(dispatch(update(reportItem)));
          break;
        case "delete":
          resolve(dispatch(remove({ id: itemId })));
          break;
        default:
          resolve(dispatch(create({ ...reportItem, report_id: id })));
          break;
      }
    }).then(() => {
      setReportItem(initReportItem);
      onFilterSearch();
    });
  };

  return (
    <>
      <ContentHeader title="Relatório de Vistoria" previousRoute="/reports" />
      <Content>
        <CalloutReport />
        <Card
          title="Itens"
          iconTitle="list"
          cardBodyClass=" p-0"
        >
          <div className="mx-4">
            <Row>
              <Grid cols="12 10">
                  <InputLabel
                    label={`${!reportItem.id ? "Incluir" : "Alterar"} item`}
                    placeholder="Informe o item..."
                    value={reportItem.item}
                    hideErros
                    onChange={(e) => {
                      const { value } = e.target;
                      setReportItem({ ...reportItem, item: value });
                    }}
                  />
                <ButtonGroup>
                  <Button
                    btnClass=" btn-tool btn-sm"
                    label="Salvar"
                    icon="save"
                    disabled={!reportItem.item}
                    onClick={() =>
                      reportItem.id ? submit("update") : submit("create")
                    }
                  />
                  <Button
                    btnClass=" btn-tool btn-sm"
                    label="Limpar"
                    icon="eraser"
                    onClick={() =>
                      setReportItem({
                        item: "",
                        order: total_items + 1,
                      })
                    }
                  />
                </ButtonGroup>
              </Grid>
              <Grid cols="12 2">
                <FormGroup>
                  <Select
                    label="Ordem:"
                    value={reportItem.order || 1}
                    options={
                      total_items > 0
                        ? [...Array(total_items + 1).keys()].map((number) => ({
                            text: number + 1,
                            value: number + 1,
                          }))
                        : [{ text: 1, value: 1 }]
                    }
                    onChange={(e) => {
                      const { value } = e.target;
                      setReportItem({ ...reportItem, order: value });
                    }}
                  />
                </FormGroup>
              </Grid>
            </Row>
            <hr />
            <Row>
              <Grid cols="12 12">
                <FormGroup>
                  <InputGroupAppend
                    type="search"
                    name="search"
                    icon="search"
                    value={filterSearch.params.search}
                    onChange={(e) =>
                      handleTableFilterSearch(e.target.name, e.target.value)
                    }
                    cursor
                    appendBtnClass=" btn-sm btn-secondary"
                    placeholder="Procurar..."
                    dataTooltipId="search-reports"
                    dataTooltipContent="Pesquisar"
                    onClickSpan={onFilterSearch}
                  />
                </FormGroup>
              </Grid>
            </Row>
          </div>
          <TableReportItems />
        </Card>
      </Content>
      <ModalDeleteReportItem
        modalId="report-item-delete"
        modalShow={modalData.modalShow}
        modalTitle="Excluir Item do Reatório"
        modalBody={
          modalData.modalContent && (
            <>
              <p>Tem certeza que deseja excluir esse item?</p>
              <dl className="row">
                <dt className="col-sm-2 text-right">Item:</dt>
                <dd className="col-sm-10">{modalData.modalContent.item}</dd>
                <dt className="col-sm-2 text-right">Ordem:</dt>
                <dd className="col-sm-10">{modalData.modalContent.order}</dd>
              </dl>
            </>
          )
        }
        hideModal={() => setModalData(false)}
        modalFooterClass=" d-flex justify-content-end"
        modalActions={
          <Button
            btnClass=" btn-primary"
            icon="check"
            label="Confirmar"
            onClick={() => {
              setModalData(false);
              submit("delete", modalData.modalContent.id);
            }}
          />
        }
      />
    </>
  );
}

export default InspectionReport;
