import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
/* Common / Layouts */
import ContentHeader from "@/common/layouts/ContentHeader";
import Content from "@/common/layouts/Content";
import Row from "@/common/layouts/Row";
import Grid from "@/common/layouts/Grid";
/* Common / Form */
import FormGroup from "@/common/form/FormGroup";
import {
  InputCheckbox,
  InputGroupAppend,
  InputLabel,
} from "@/common/form/controls/Input";
/* Components */
import Card from "@/components/Card";
import { Button } from "@/components/Button";
import { ButtonGroup } from "@/components/ButtonGroup";
import Image from "@/components/Image";
/* Components / Table */
import { useTableFilterSearch } from "@/components/Table/TableFilterSearch";
import { Table } from "@/components/Table/Table";
/* Components / Modal */
import { useModal } from "@/components/Modal/ModalStore";
import { Modal as ModalDeleteDescription } from "@/components/Modal/Modal";
import { useCalloutReport } from "../../CalloutReport";
import { RowImages, useHandleRowImages } from "@/components/Table/RowImages";
/* Reducers */
import {
  index,
  create,
  update,
  remove,
} from "@/reducers/Reports/ReportItemDescriptionsReducer";
import { remove_images } from "@/reducers/Reports/ReportItemDescriptionImagesReducer";
import LinkTooltip from "@/components/LinkTooltip";

function Index() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { data } = useSelector((state) => state.reportItemDescriptions);
  const { filterSearch, setFilterSearch, handleTableFilterSearch } =
    useTableFilterSearch();
  const { modalData, setModalData } = useModal();
  const { CalloutReport, setReportInfo } = useCalloutReport();

  const initReportItemDescription = useMemo(
    () => ({
      description: "",
      report_item_id: id,
    }),
    [id]
  );

  const [reportItemDescription, setReportItemDescription] = useState(
    initReportItemDescription
  );

  const onFilterSearch = useCallback(() => {
    const filterSearchURL = {
      params: {
        ...filterSearch.params,
        report_item_id: id,
        fields: "description",
      },
    };
    setFilterSearch(filterSearchURL);
    dispatch(index(filterSearchURL)).then(({ payload }) => setReportInfo(payload.details_report_item.report));
    // eslint-disable-next-line
  },[id]);

  useEffect(() => {
    onFilterSearch();
  }, [filterSearch.params.currentPage, filterSearch.params.itemsPerPage, onFilterSearch]);

  const handleResetReportItemDescription = () => {
    onFilterSearch();
    setReportItemDescription(initReportItemDescription);
  };

  const TableReportItemDescriptions = () => {
    const fields = [
      { key: "description", label: "Descrição", thClass: "w-100" },
    ];

    const RowsReportItemDescriptions = () => {
      const [expandedRow, setExpandedRow] = useState(null);
      const toggleRow = (id) => {
        setExpandedRow(expandedRow === id ? null : id);
      };

      const {
        selectedImages,
        setSelectedImages,
        confirmRemoveImages,
        setConfirmRemoveImages,
      } = useHandleRowImages();

      const handleRemoveImages = () => {
        dispatch(remove_images({ images_id: selectedImages.join("?") })).then(
          () => {
            onFilterSearch();
          }
        );
      };

      return data?.report_item_descriptions?.data.map((row, idx) => (
        <React.Fragment key={idx}>
          <tr>
            {fields.map(({ key, tdClass }, idx) => (
              <td key={idx} className={tdClass}>
                {row[key]}
              </td>
            ))}
            <td>
              <ButtonGroup>
                <Button
                  btnClass=" btn-tool"
                  onClick={() => toggleRow(row.id)}
                  dataTooltipId={`descriptions-images_${row.id}`}
                  dataTooltipContent="Imagens"
                >
                  <div className="d-flex">
                    <i className="fas fa-images mr-2"></i>
                    <i
                      className={`fa ${
                        expandedRow === row.id ? "fa-caret-up" : "fa-caret-down"
                      }`}
                    ></i>
                  </div>
                </Button>
                <Button
                  btnClass=" btn-tool"
                  dataTooltipId="tooltip-edit-description"
                  dataTooltipContent="Editar"
                  onClick={() =>
                    setReportItemDescription({
                      id: row.id,
                      description: row.description,
                      report_item_id: row.report_item_id,
                    })
                  }
                >
                  <i className="fas fa-pencil-alt"></i>
                </Button>
                <Button
                  btnClass=" btn-tool"
                  icon="trash-alt"
                  dataTooltipId="description-delete"
                  dataTooltipContent="Apagar"
                  onClick={() =>
                    setModalData({
                      ...modalData,
                      modalShow: !modalData.modalShow,
                      modalContent: row,
                    })
                  }
                />
              </ButtonGroup>
            </td>
          </tr>
          {expandedRow === row.id && (
            <RowImages
              colSpan={fields.length}
              content={
                <>
                  <Row rowClass=" mb-3">
                    <Grid cols="12 4">
                      <ButtonGroup>
                        <a
                          href={`/description/${row.id}/images`}
                          className="btn-sm"
                        >
                          <i className="fas fa-plus mr-2"></i>Incluir imagens
                        </a>
                        <Tooltip id="tooltip-description-create-images" />
                        <Button
                          btnClass=" btn-sm text-danger"
                          label="Excluir imagens"
                          icon="trash-alt"
                          disabled={!selectedImages.length}
                          onClick={() => setConfirmRemoveImages(true)}
                        />
                      </ButtonGroup>
                    </Grid>
                    {confirmRemoveImages && (
                      <Grid cols="12 8">
                        <span>
                          Tem certeza que deseja excluir as imagens
                          selecionadas?
                        </span>
                        <hr />
                        <ButtonGroup>
                          <Button
                            btnClass=" btn-sm btn-tool"
                            icon="check"
                            label="Sim"
                            onClick={handleRemoveImages}
                          />
                          <Button
                            btnClass=" btn-sm btn-tool"
                            icon="times"
                            label="Não"
                            onClick={() => setConfirmRemoveImages(false)}
                          />
                        </ButtonGroup>
                      </Grid>
                    )}
                  </Row>
                  {!row.report_item_description_images.length ? (
                    <div className="text-center">
                      <span>Nenhuma imagem encontrada.</span>
                    </div>
                  ) : (
                    <Row>
                      {row.report_item_description_images.map(
                        ({ id, photo }, idx) => (
                          <Grid key={idx} cols="12 4">
                            <InputCheckbox
                              value={id}
                              onChange={(e) => {
                                const { value } = e.target;
                                setSelectedImages((prev) => {
                                  setConfirmRemoveImages(false);
                                  if (prev.includes(value)) {
                                    return prev.filter(
                                      (item) => item !== value
                                    );
                                  } else {
                                    return [...prev, value];
                                  }
                                });
                              }}
                            />
                            <Image
                              imageURL={`${process.env.REACT_APP_REPORT_ITEM_DESCRIPTION_IMAGES_V1}?report_id=${data?.details_report_item?.report?.id}&description_id=${row.id}&filename=${photo}`}
                              filename={photo}
                            />
                          </Grid>
                        )
                      )}
                    </Row>
                  )}
                </>
              }
            />
          )}
        </React.Fragment>
      ));
    };

    return (
      <Table
        {...{ fields }}
        totalItems={data?.total_item_descriptions}
        rows={<RowsReportItemDescriptions />}
        HasPagination
        paginationClass=" justify-content-center"
        itemsPerPage={filterSearch.params.itemsPerPage}
        currentPage={filterSearch.params.currentPage}
        onItemsPerPageChange={(key, value) =>
          handleTableFilterSearch(key, value)
        }
        onPageChange={(currentPage) =>
          handleTableFilterSearch("currentPage", currentPage)
        }
      />
    );
  };

  const submit = (method, id) => {
    return new Promise((resolve) => {
      switch (method) {
        case "update":
          resolve(dispatch(update(reportItemDescription)));
          break;
        case "remove":
          resolve(dispatch(remove({ id })));
          break;
        default:
          resolve(dispatch(create(reportItemDescription)));
          break;
      }
    }).then(() => {
      handleResetReportItemDescription();
    });
  };

  return (
    <>
      <ContentHeader
        title="Descrições de Item"
        previousRoute={`/items/${data?.details_report_item?.report.id}/report`}
      />
      <Content>
        <CalloutReport />
        <Card
          title="Descrições"
          iconTitle="list"
          cardTools={
              <LinkTooltip
                path={`/item/${id}/description/create`}
                icon="plus"
                linkClass=" btn-tool"
                dataTooltipId="item-description-create"
                dataTooltipContent="Incluir"
              />
          }
          cardBodyClass=" p-0"
        >
          <Row rowClass=" mx-4">
            <Grid cols={`12 ${reportItemDescription.id ? "4" : "12"}`}>
              <FormGroup>
                <span className="text-bold">Item:</span>
                <div>{data?.details_report_item?.report_item.item}</div>
              </FormGroup>
            </Grid>
            {reportItemDescription.id && (
              <Grid cols="12 8">
                  <InputLabel
                    label="Alterar descrição:"
                    placeholder="Informe a descrição..."
                    value={reportItemDescription.description}
                    hideErros
                    onChange={(e) => {
                      const { value } = e.target;
                      setReportItemDescription({
                        ...reportItemDescription,
                        description: value,
                      });
                    }}
                  />
                <ButtonGroup>
                  <Button
                    label="Salvar"
                    btnClass=" btn-tool"
                    icon="save"
                    disabled={!reportItemDescription.description}
                    onClick={() =>
                      submit(reportItemDescription.id ? "update" : "create")
                    }
                  />
                  <Button
                    label="Limpar"
                    btnClass=" btn-tool"
                    icon="eraser"
                    onClick={() =>
                      setReportItemDescription(initReportItemDescription)
                    }
                  />
                </ButtonGroup>
              </Grid>
            )}
          </Row>
          <Grid cols="12 12">
            <hr />
            <FormGroup formClass=" mx-3">
              <InputGroupAppend
                type="search"
                name="search"
                icon="search"
                value={filterSearch.params.search}
                onChange={(e) =>
                  handleTableFilterSearch(e.target.name, e.target.value)
                }
                cursor
                appendBtnClass=" btn-sm btn-secondary"
                placeholder="Procurar..."
                dataTooltipId="search-reports"
                dataTooltipContent="Pesquisar"
                onClickSpan={onFilterSearch}
              />
            </FormGroup>
          </Grid>
          <TableReportItemDescriptions />
        </Card>
        <ModalDeleteDescription
          modalId="description-delete"
          modalShow={modalData.modalShow}
          modalTitle="Excluir Descrição"
          modalBody={
            modalData.modalContent && (
              <>
                <p>Tem certeza que deseja excluir essa descrição?</p>
                <dl className="row">
                  <dt className="col-sm-2 text-right">Descrição:</dt>
                  <dd className="col-sm-10">
                    {modalData.modalContent.description}
                  </dd>
                </dl>
              </>
            )
          }
          hideModal={() => setModalData(false)}
          modalFooterClass=" d-flex justify-content-end"
          modalActions={
            <Button
              btnClass=" btn-primary"
              icon="check"
              label="Confirmar"
              onClick={() => {
                setModalData(false);
                submit("remove", modalData.modalContent.id);
              }}
            />
          }
        />
      </Content>
    </>
  );
}

export default Index;
