import { createSlice } from '@reduxjs/toolkit';
import { asyncApiThunkFactory, handlePending, handleReject, handleFulfilled, handleFulfilledSubmit } from '../asyncThunkFactory';

const initialState = {
    data: {},
    exportWordUrl: null,
    hasLoading: false,
    hasError: null
};

export const index = asyncApiThunkFactory('functionality_tests/index', `${process.env.REACT_APP_FUNCTIONALITY_TESTS_V1}`, 'get');
export const show = asyncApiThunkFactory('functionality_tests/show', `${process.env.REACT_APP_FUNCTIONALITY_TESTS_V1}`, 'get');
export const create = asyncApiThunkFactory('functionality_tests/create', `${process.env.REACT_APP_FUNCTIONALITY_TESTS_V1}`, 'post');
export const update = asyncApiThunkFactory('functionality_tests/update', `${process.env.REACT_APP_FUNCTIONALITY_TESTS_V1}`, 'put');

const functionalityTestsSlice = createSlice({
    name: 'functionality_tests',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // index
            .addCase(index.pending, handlePending)
            .addCase(index.fulfilled, handleFulfilled)
            .addCase(index.rejected, handleReject)
            // show
            .addCase(show.pending, handlePending)
            .addCase(show.fulfilled, handleFulfilled)
            .addCase(show.rejected, handleReject)
            // create
            .addCase(create.pending, handlePending)
            .addCase(create.fulfilled, handleFulfilledSubmit)
            .addCase(create.rejected, handleReject)
            // update
            .addCase(update.pending, handlePending)
            .addCase(update.fulfilled, handleFulfilledSubmit)
            .addCase(update.rejected, handleReject)
    },
});

export default functionalityTestsSlice.reducer;