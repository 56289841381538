import React from "react";
import { Tooltip } from "react-tooltip";

export function Button({
  btnClass,
  icon,
  label,
  type,
  onClick,
  disabled,
  dataTooltipId,
  dataTooltipContent,
  children,
}) {
  return (
    <>
    <button
      className={`btn${btnClass}`}
      {...{ type, onClick, disabled }}
      data-tooltip-id={`tooltip-${dataTooltipId}`}
      data-tooltip-content={dataTooltipContent}
    >
      {icon && <i className={`fas fa-${icon} mr-2`}></i>}
      <span>{label}</span>
      {children}
    </button>
    {dataTooltipId && (
      <Tooltip id={`tooltip-${dataTooltipId}`} />
    )}
    </>
  );
}
