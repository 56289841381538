import React, { useState } from "react";

export function useHandleRowImages() {
  const [selectedImages, setSelectedImages] = useState([]);
  const [confirmRemoveImages, setConfirmRemoveImages] = useState(false);

  return {
    selectedImages,
    setSelectedImages,
    confirmRemoveImages,
    setConfirmRemoveImages,
  };
}

export const RowImages = ({ content, colSpan }) => (
  <tr className="collapse show">
    <td className="p-3 border-0 bg-white" {...{ colSpan }}>
      {content}
    </td>
  </tr>
);
