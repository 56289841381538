import React from "react";
import { useDispatch } from "react-redux";
import Gravatar from "react-gravatar";
import { Link } from "react-router-dom";
import { logout } from "@/reducers/Auth/AuthReducer";
import { DropdownItem } from "@/components/DropdownItem";

function Header({ handleDarkMode, darkMode }) {
  const { name, email } = JSON.parse(localStorage.getItem("userData"));

  const dispatch = useDispatch();

  const handleLogout = () => {
    handleDarkMode(false);
    dispatch(logout());
  };

  return (
    <header>
      <nav
        className={`main-header navbar navbar-expand navbar-light ${
          darkMode ? "bg-dark" : ""
        }`}
      >
        <ul className="navbar-nav">
          <li className="nav-item">
            <a
              href="#/"
              className={`nav-link ${darkMode ? "text-white" : ""}`}
              data-widget="pushmenu"
              role="button"
            >
              <i className="fas fa-bars"></i>
            </a>
          </li>
        </ul>
        <ul className="navbar-nav ml-auto">
          <DropdownItem icon="adjust fa-lg" dropdownClass="dropdown-menu-right">
            <>
              <Link
                className="dropdown-item"
                style={{ cursor: "pointer" }}
                onClick={() => handleDarkMode(false)}
              >
                <i className="fas fa-sun mr-2"></i>
                <span>Modo Claro</span>
              </Link>
              <Link
                className="dropdown-item"
                style={{ cursor: "pointer" }}
                onClick={() => handleDarkMode(true)}
              >
                <i className="fas fa-moon mr-2"></i>
                <span>Modo Escuro</span>
              </Link>
            </>
          </DropdownItem>
          <li className="nav-item dropdown user user-menu">
            <a
              href="#/"
              className={`nav-link dropdown-toggle ${
                darkMode ? "text-white" : ""
              }`}
              data-toggle="dropdown"
            >
              <Gravatar
                email={email}
                className="user-image img-circle elevation-2"
              />
              <span className="hidden-xs d-none d-sm-inline-block">{name}</span>
            </a>
            <ul className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
              <li className="user-header">
                <Gravatar email={email} className="img-circle elevation-2" />
                <p>
                  {name}
                  <small>{email}</small>
                </p>
              </li>
              <li className="user-footer">
                <div className="d-flex justify-content-betweeen">
                  <div>
                    <a
                      href="/configuracoes"
                      className="btn btn-default btn-flat"
                      role="button"
                    >
                      <i className="fas fa-cogs mr-2"></i>Configurações
                    </a>
                  </div>
                  <div>
                    <Link
                      className="btn btn-default btn-flat"
                      role="button"
                      onClick={handleLogout}
                    >
                      <i className="fas fa-sign-out-alt mr-2"></i>Logout
                    </Link>
                  </div>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
