import React from "react";
import Row from "../common/layouts/Row";
import Grid from "../common/layouts/Grid";

function Callout({ title, icon, id, content }) {
  return (
    <div className="callout callout-info p-3 mb-3">
      <Row>
        <Grid cols="12 12">
          <h5>
            <i className={`fas fa-${icon} mr-2`}></i>
            {title}
            {id && <div className="float-right">#{id}</div>}
          </h5>
        </Grid>
      </Row>
      <Row rowClass=" invoice-info mt-1">
        {content}
      </Row>
    </div>
  );
}

export default Callout;
