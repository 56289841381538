import React from "react";
import { Pagination } from "../Pagination";
import { itemsPerPageDefault } from "@/common/constants";

export const Table = ({
  tableClass,
  theadClass,
  fields,
  rows,
  hideActions,
  HasPagination,
  paginationClass,
  totalItems,
  itemsPerPage,
  currentPage,
  onItemsPerPageChange,
  onPageChange,
}) => {
  return (
    <>
      <table
        className={`table${tableClass ?? " table-striped table-valign-middle"}`}
      >
        <thead className={`${theadClass ?? ""}`}>
          <tr>
            {fields.map(({ label, thClass }, idx) => (
              <th key={idx} className={thClass}>
                {label}
              </th>
            ))}
            {!hideActions && <th className="w-25"></th>}
          </tr>
        </thead>
        {totalItems > 0 && <tbody>{rows}</tbody>}
      </table>
      {totalItems === 0 && (
        <div className="d-flex justify-content-center p-3">
          Nenhum registro encontrado.
        </div>
      )}
      {totalItems > 0 && (
        <>
          {HasPagination && (
            <div className="card-footer clearfix">
              <Pagination
                itemsPerPageOptions={itemsPerPageDefault}
                {...{
                  paginationClass,
                  totalItems,
                  itemsPerPage,
                  currentPage,
                  onItemsPerPageChange,
                  onPageChange,
                }}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};
