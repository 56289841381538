import React from "react";

const Content = ({ children, contentClass }) => (
  <section className="content">
    <div className={`container-fluid pb-2 ${contentClass ?? ""}`}>
    {children}
    </div>
    </section>
);

export default Content;
