import { useState } from "react"
import { searchDefault } from "@/common/constants"

export function useTableFilterSearch() {
    const [filterSearch, setFilterSearch] = useState(searchDefault);

    const handleTableFilterSearch = (key, value) => (
        setFilterSearch((prevFilterSearch) => ({
            params: {
                ...prevFilterSearch.params,
                [key]: value
            }
        }))
    );

    const handleInputFilterSearch = (e) => {
        const { name, value, checked } = e.target;
        if (name === "fields") {
            setFilterSearch((prevFilterSearch) => {
                if (checked) {
                    return {
                        params: {
                            ...prevFilterSearch.params,
                            fields: [...prevFilterSearch.params.fields.split("?"), value].join("?"),
                        },
                    };
                } else {
                    return {
                        params: {
                            ...prevFilterSearch.params,
                            fields: prevFilterSearch.params.fields.split("?").filter(
                                (field) => field !== value
                            ).join("?"),
                        },
                    };
                }
            });
        } else {
            handleTableFilterSearch(name, value);
        }
    };

    return {
        filterSearch,
        setFilterSearch,
        handleTableFilterSearch,
        handleInputFilterSearch,
    }
}