import React from "react";
import { Tooltip } from "react-tooltip";

function Box({ label, icon, routeList, routeAdd, childrens, totalItems }) {
  return (
    <div className="card h-100">
      <div className="card-header border-0">
        <h3 className="card-title">
          <i className={`fas fa-${icon} mr-2`}></i>
          {label}
        </h3>
        <div className="card-tools">
          <a
            href={routeList}
            className="btn btn-sm btn-tool"
            data-tooltip-id="tooltip-box-view"
            data-tooltip-content="Consultar"
          >
            <i className="fas fa-search"></i>
          </a>
          <Tooltip id="tooltip-box-view" />
          <a
            href={routeAdd}
            className="btn btn-sm btn-tool"
            data-tooltip-id="tooltip-box-create"
            data-tooltip-content="Incluir"
          >
            <i className="fas fa-plus"></i>
          </a>
          <Tooltip id="tooltip-box-create" />
        </div>
      </div>
      <div className="card-body p-0">
        {childrens && (
          <div className="pl-4 pr-4 pt-2">
            {childrens.map(({ label, value, icon, iconClass }, idx) => (
              <div
                key={idx}
                className={`d-flex justify-content-between align-items-center${
                  idx === 0 && childrens.length > 1 ? " mb-3 border-bottom" : ""
                }`}
              >
                <p className={`${iconClass} text-xl`}>
                  <i className={`fas fa-${icon}`}></i>
                </p>
                <p className="d-flex flex-column text-right">
                  <span className="font-weight-bold">{value}</span>
                  <span className="text-muted">{label.toUpperCase()}</span>
                </p>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="card-footer bg-transparent text-muted">
      <small className="float-right">Total: {totalItems}</small>
      </div>
    </div>
  );
}

export default Box;
