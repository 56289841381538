import React, { useEffect, useMemo, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
/* Common */
import { searchDefault } from "@/common/constants";
/* Common / Layouts */
import Content from "@/common/layouts/Content";
import ContentHeader from "@/common/layouts/ContentHeader";
/* Common / Form */
import { FormControl as FormReport } from "@/common/form/FormControl";
import {
  InputCheckbox,
  useInputControl,
} from "@/common/form/controls/Input";
import FormGroup from "@/common/form/FormGroup";
/* Components */
import Card from "@/components/Card";
/* Components / Table */
import { Table } from "@/components/Table/Table";
import { Button } from "@/components/Button";
/* Reducers */
import { index, show, create, update } from "@/reducers/Reports/ReportsReducer";

function DetailsReport() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: reports } = useSelector((state) => state.reports);
  const [report, setReport] = useState({});
  const initReport = useMemo(
    () => ({
      title: "",
      subtitle: "",
      address: "",
      status: "Em andamento",
    }),
    []
  );

  const { errors, inputValidator, isFormValid } = useInputControl();

  useEffect(() => {
    if (id) dispatch(show({ id })).then(({ payload }) => setReport(payload));
    else setReport(initReport);
    // eslint-disable-next-line
  }, [id, dispatch]);

  const [copyFromReport, setCopyFromReport] = useState(false);

  const inputsForm = [
    {
      label: "Título:",
      name: "title",
      type: "text",
      value: report.title,
      cols: "12 12",
      validators: [{ required: true }]
    },
    {
      label: "Subtítulo:",
      name: "subtitle",
      type: "text",
      value: report.subtitle,
      cols: "12 12",
      validators: [{ required: true }]
    },
    {
      label: "Endereço:",
      name: "address",
      type: "text",
      value: report.address,
      cols: "12 12",
      validators: [{ required: true }]
    },
    {
      label: "Status:",
      name: "status",
      type: "radio",
      value: report.status,
      options: [
        { label: "Em andamento", value: "Em andamento" },
        { label: "Concluído", value: "Concluído" },
      ],
      cols: "12 12",
      hide: !id ?? false,
    },
  ];

  const handleReport = (e) => {
    const { name, value } = e.target;
    setReport({ ...report, [name]: value });
  };

  const TableReportsInProgress = () => {
    const fields = [
      { label: "Selecionar", thClass: "text-center w-25" },
      { key: "title", label: "Título" },
      {
        key: "address",
        label: "Endereço",
        thClass: "d-none d-md-table-cell",
        tdClass: "d-none d-md-table-cell",
      },
    ];

    const handleChangeReportInProgress = (item) =>
      setReport((prev) =>
        Object.keys(prev).reduce((acc, key) => {
          if (prev.copy_images) {
            acc.copy_images = prev.copy_images;
          }
          acc[key] = item[key];
          return { ...acc, copy_report_id: item.id };
        }, {})
      );

    const RowsReportsInProgress = () =>
      reports.data.map((row, idx) => (
        <tr key={idx}>
          <td className="text-center">
            <input
              type="radio"
              checked={report.copy_report_id === row.id}
              onChange={() => handleChangeReportInProgress(row)}
              style={{ cursor: "pointer" }}
            />
          </td>
          {fields
            .filter(({ key }) => key)
            .map(({ key, tdClass }, idx) => (
              <td key={idx} className={tdClass}>
                {row[key]}
              </td>
            ))}
        </tr>
      ));

    return (
      <Table
        {...{ fields }}
        rows={<RowsReportsInProgress />}
        totalItems={reports?.total}
        tableClass=" table-striped table-valign-middle"
        hideActions
      />
    );
  };

  const handleReportsInProgress = (e) => {
    const { checked } = e.target;
    if (checked) {
      dispatch(
        index({
          params: { ...searchDefault.params, status: "Em andamento" },
        })
      );
    } else {
      setReport(initReport);
    }
    setCopyFromReport(checked);
  };

  const addOrEdit = () => {
    dispatch(id ? update(report) : create(report)).then(() =>
      navigate("/reports")
    );
  };

  useEffect(() => {
    inputValidator(report, inputsForm);
    // eslint-disable-next-line
  }, [report]);

  return (
    <>
      <ContentHeader
        title={`${id ? "Editar" : "Incluir"} Relatório`}
        previousRoute="/reports"
      />
      <Content>
        <Card
          title="Detalhes"
          iconTitle="list-alt"
          cardTools={
            <>
              <Button
                icon="eraser"
                btnClass=" btn-tool btn-sm"
                dataTooltipId="reset-report"
                dataTooltipContent="Limpar"
                onClick={() => {
                  setReport(initReport);
                  setCopyFromReport(false);
                }}
              />
              <Button
                icon="save"
                btnClass=" btn-tool btn-sm"
                dataTooltipId="save-report"
                dataTooltipContent="Salvar"
                disabled={!isFormValid}
                onClick={addOrEdit}
              />
            </>
          }
          cardBodyClass=" p-0"
        >
          {!id && (
            <div className="pl-4 pr-4 ml-2">
              <FormGroup>
                <InputCheckbox
                  id="copyCheckReport"
                  type="checkbox"
                  label="Copiar relatório"
                  checked={copyFromReport}
                  checkClass=" btn btn-tool text-md"
                  onChange={handleReportsInProgress}
                />
                {copyFromReport && (
                  <div>
                    <InputCheckbox
                      id="copyCheckReportImages"
                      type="checkbox"
                      label="Incluir fotos"
                      checkClass=" btn btn-tool text-md"
                      onChange={(e) =>
                        setReport((prev) => {
                          const { checked } = e.target;
                          const { copy_images, ...rest } = prev;
                          return checked
                            ? { copy_images: true, ...prev }
                            : rest;
                        })
                      }
                    />
                  </div>
                )}
              </FormGroup>
            </div>
          )}
          {!copyFromReport ? (
            <div className="pl-4 pr-4 pb-4">
              <FormReport
                {...{
                  inputsForm,
                  errors,
                }}
                handleInputForm={handleReport}
              />
            </div>
          ) : (
            <TableReportsInProgress />
          )}
        </Card>
      </Content>
    </>
  );
}

export default DetailsReport;
