import React from "react";
import Row from "../common/layouts/Row";
import Grid from "../common/layouts/Grid";

export function Pagination({
  totalItems,
  itemsPerPage,
  currentPage,
  itemsPerPageOptions,
  onPageChange,
  onItemsPerPageChange,
}) {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const maxPageItems = 4;
  const halfMaxPageItems = Math.floor(maxPageItems / 2);
  const startItem = (currentPage - 1) * itemsPerPage + 1;
  const endItem =
    startItem === 1 && itemsPerPage <= totalItems
      ? itemsPerPage
      : Math.min(startItem + itemsPerPage - 1, totalItems);

  const getPaginationItems = () => {
    let startPage = currentPage - halfMaxPageItems;
    let endPage = currentPage + halfMaxPageItems;

    if (startPage < 1) {
      startPage = 1;
      endPage = Math.min(totalPages, maxPageItems);
    }

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, totalPages - maxPageItems + 1);
    }

    return Array.from(
      { length: endPage - startPage + 1 },
      (_, i) => startPage + i
    );
  };

  const handleClick = (page) => {
    onPageChange(page);
    //   if (page !== currentPage && page > 0 && page <= totalPages) {
    //     onPageChange(page);
    //   }
  };

  return (
    <Row>
      <Grid cols="12 4">
        <div className="form-group d-flex align-items-center">
          <span className="mr-2">Itens por página:</span>
          <select
            name="itemsPerPage"
            value={itemsPerPage}
            className="form-control form-control-sm w-auto"
            onChange={(e) => onItemsPerPageChange(e.target.name, e.target.value)}
          >
            {itemsPerPageOptions.map(({ text, value }, idx) => (
              <option key={idx} {...{ value }}>
                {text}
              </option>
            ))}
          </select>
        </div>
      </Grid>
      <Grid cols="12 4">
        <ul className="pagination pagination-sm justify-content-center">
          <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
            <button className="page-link" onClick={() => handleClick(1)}>
              Primeira
            </button>
          </li>
          <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
            <button
              className="page-link"
              onClick={() => handleClick(currentPage - 1)}
            >
              &laquo;
            </button>
          </li>
          {getPaginationItems().map((page) => (
            <li
              key={page}
              className={`page-item ${currentPage === page ? "active" : ""}`}
            >
              <button className="page-link" onClick={() => handleClick(page)}>
                {page}
              </button>
            </li>
          ))}
          <li
            className={`page-item ${
              currentPage === totalPages ? "disabled" : ""
            }`}
          >
            <button
              className="page-link"
              onClick={() => handleClick(currentPage + 1)}
            >
              &raquo;
            </button>
          </li>
          <li
            className={`page-item ${
              currentPage === totalPages ? "disabled" : ""
            }`}
          >
            <button
              className="page-link"
              onClick={() => handleClick(totalPages)}
            >
              Última
            </button>
          </li>
        </ul>
      </Grid>
      <Grid cols="12 4" classGrid=" d-none d-md-table-cell">
        <span className="d-flex justify-content-end">
          {totalItems === 1 ? (
            <span>Mostrando 1 registro</span>
          ) : (
            <span>
              Mostrando {startItem} a {endItem} de {totalItems} registros
            </span>
          )}
        </span>
      </Grid>
    </Row>
  );
}
