import { createSlice } from '@reduxjs/toolkit';
import { asyncThunkFactory, asyncApiThunkFactory } from '../asyncThunkFactory';
import Cookies from 'js-cookie';

const initialState = {
  hasAuth: false,
  isLoading: false,
  hasError: null,
};

export const login = asyncThunkFactory('auth/login', `${process.env.REACT_APP_API_URL}/login`, 'post', false);
export const validateToken = asyncApiThunkFactory('auth/validateToken', `${process.env.REACT_APP_AUTH_V1}/validate_token`, 'get');

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout(state) {
      state.hasAuth = false;
      Cookies.remove('XSRF-TOKEN');
      localStorage.clear();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasAuth = true;
        Cookies.set('XSRF-TOKEN', action.payload.accessToken.token, { secure: true, sameSite: 'Strict' });
        localStorage.setItem("userData", JSON.stringify(action.payload.userData));
        localStorage.setItem("darkMode", false);
      })
      .addCase(login.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(validateToken.pending, (state) => {
        state.hasError = null;
      })
      .addCase(validateToken.fulfilled, (state) => {
        state.hasAuth = true;
      })
      .addCase(validateToken.rejected, (state, action) => {
        state.hasError = action.payload;
        Cookies.remove('XSRF-TOKEN');
        localStorage.clear();
      });
  },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;