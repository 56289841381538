import React, { useState } from "react";
import FormGroup from "../FormGroup";
import { Tooltip } from "react-tooltip";

export const useInputControl = () => {
  const [errors, setErrors] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [images, setImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);

  const inputValidator = (item, inputs) => {
    const newErrors = {};
    inputs
      .filter(({ validators }) => validators)
      .map(({ name, validators }) =>
        validators.map(({ required, min }) => {
          if (!item[name] && required) newErrors[name] = "Campo obrigatório.";
          if (item[name] && item[name].length < min)
            newErrors[name] = `Mínimimo ${min} caracteres.`;
          return newErrors;
        })
      );
    setErrors(newErrors);
    setIsFormValid(Object.keys(newErrors).length === 0);
    return Object.keys(newErrors).length === 0;
  };

  const hasEmptyFields = (obj) => {
    return Object.entries(obj).some(([_, value]) => { return value === '' || value === null || value === undefined; });
};

  return {
    imagePreviews,
    setImagePreviews,
    images,
    setImages,
    errors,
    isFormValid,
    hasEmptyFields,
    inputValidator,
  };
};

export const InputLabel = ({
  label,
  name,
  type,
  value,
  placeholder,
  inputClass,
  labelClass,
  onChange,
  disabled,
  errors
}) => (
  <FormGroup>
    {label && <label className={labelClass}>{label}</label>}
    <input
      className={`form-control${inputClass ?? ""} ${
        errors && errors[name] ? " is-invalid" : ""
      }`}
      value={value || ""}
      {...{ type, name, placeholder, onChange, disabled }}
    />
    {errors && errors[name] && (
      <div className="invalid-feedback">{errors[name]}</div>
    )}
  </FormGroup>
);

export const InputCheckbox = ({
  id,
  label,
  name,
  checked,
  value,
  checkClass,
  onChange,
  disabled,
}) => (
  <div className={`form-check${checkClass ?? ""}`}>
    <input
      className={`form-check-input`}
      type="checkbox"
      {...{ id, name, checked, value, onChange, disabled }}
      style={{ cursor: "pointer" }}
    />
    <label className="form-check-label" htmlFor={id}>
      {label}
    </label>
  </div>
);

export const InputRadio = ({
  label,
  type,
  name,
  options,
  value,
  onChange,
  errors,
  hideErros,
}) => (
  <div>
    <label>
      {!hideErros && errors[name] ? (
        <>
          {label}
          <br />
          <small className="text-danger">Campo obrigatório.</small>
        </>
      ) : (
        label
      )}
    </label>
    <div className="d-flex flex-wrap">
      {options.map((option, idx) => (
        <div key={idx} className="form-check mr-3 mb-2">
          <input
            {...{ type, name, onChange }}
            value={option.value}
            className={`form-check-input${
              !hideErros && errors[name] ? " is-invalid" : ""
            }`}
            checked={value === option.value}
            style={{
              cursor: "pointer",
            }}
          />
          <label className="form-check-label">{option.label}</label>
        </div>
      ))}
    </div>
  </div>
);

export const InputGroupAppend = ({
  label,
  name,
  type,
  value,
  placeholder,
  inputClass,
  append,
  icon,
  appendBtnClass,
  appendBtnClick,
  onClickSpan,
  onChange,
  cursor,
  hasSelect,
  optionsSelect,
  valueSelect,
  onChangeSelect,
  labelClass,
  dataTooltipId,
  dataTooltipContent,
}) => {
  return (
    <>
      {label && <label className={labelClass}>{label}</label>}
      <div className="input-group">
        {hasSelect && (
          <select
            className={`form-control col-4${inputClass ?? ""}`}
            value={valueSelect}
            onChange={onChangeSelect}
          >
            {optionsSelect.map(({ label, value }, idx) => (
              <option key={idx} value={value}>
                {label}
              </option>
            ))}
          </select>
        )}
        <input
          className={`form-control${inputClass ?? ""}`}
          {...{ type, name, placeholder, onChange, value }}
        />
        <div className="input-group-append">
          {!append && (
            <span
              className="input-group-text"
              onClick={onClickSpan}
              style={{ cursor: cursor ? "pointer" : "" }}
              data-tooltip-id={`tooltip-${dataTooltipId}`}
              data-tooltip-content={dataTooltipContent}
            >
              <i className={`fas fa-${icon}`}></i>
            </span>
          )}
          {append === "btn" && (
            <button
              onClick={appendBtnClick}
              type="button"
              className={`btn${appendBtnClass ?? ""}`}
              data-tooltip-id={`tooltip-${dataTooltipId}`}
              data-tooltip-content={dataTooltipContent}
            >
              <i className={`fas fa-${icon}`}></i>
            </button>
          )}
          <Tooltip id={`tooltip-${dataTooltipId}`} />
        </div>
      </div>
    </>
  );
};

export const TextArea = ({
  rows,
  label,
  name,
  value,
  resize,
  onChange,
  errors,
}) => (
  <FormGroup>
    <label>{label}</label>
    <textarea
      className={`form-control${
        errors && errors[name] ? " is-invalid" : ""
      }`}
      {...{ rows, name, onChange }}
      value={value || ""}
      style={{ resize }}
    />
    {errors && errors[name] && (
      <div className="invalid-feedback">{errors[name]}</div>
    )}
  </FormGroup>
);
