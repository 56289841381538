import { configureStore } from "@reduxjs/toolkit";
import { reducer as toastr } from 'react-redux-toastr';
import auth from "./Auth/AuthReducer";
import loading from "../components/Loading/LoadingReducer";
import dashboard from "./Dashboard/DashboardReducer";
import reports from "./Reports/ReportsReducer";
import reportItems from "./Reports/ReportItemsReducer";
import reportItemDescriptions from "./Reports/ReportItemDescriptionsReducer";
import reportItemDescriptionImages from "./Reports/ReportItemDescriptionImagesReducer";
import functionalityTests from "./Reports/FunctionalityTestsReducer";
import inspections from "./Inspections/InspectionsReducer";
import inspectionVisits from "./Inspections/InspectionVisitsReducer";
import inspectionVisitImages from "./Inspections/InspectionVisitImagesReducer";

const store = configureStore({
    reducer: {
      toastr,
      auth,
      dashboard,
      loading,
      reports,  
      reportItems,
      reportItemDescriptions,
      reportItemDescriptionImages,
      functionalityTests,  
      inspections,
      inspectionVisits,
      inspectionVisitImages,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
    devTools: process.env.NODE_ENV !== 'production',
  });
  
  export default store;