import React from "react";
import Grid from "../layouts/Grid";
import { InputLabel, InputRadio, TextArea } from "./controls/Input";
import Select from "./controls/Select";
import Row from "../layouts/Row";
import FormGroup from "./FormGroup";

export function FormControl({ inputsForm, errors, handleInputForm }) {

  const onChangeInputForm = (e) => {
    handleInputForm(e);
  };

  const handleTypeInput = ({
    label,
    name,
    type,
    options,
    value,
    rows,
    resize,
    multiple,
    accept
  }) => {
    switch (type) {
      case "radio":
        return (
          <InputRadio
            {...{ label, name, type, options, value, errors }}
            onChange={onChangeInputForm}
          />
        );
      case "file":
        return (
          <FormGroup>
            <label>{label}</label>
            <input
              type="file"
              {...{ name, multiple, accept }}
              className="form-control"
            />
          </FormGroup>
        );
      case "select":
        return <Select {...{ label, name, options, value }} onChange={onChangeInputForm} />;
      case "textarea":
        return (
            <TextArea 
              {...{ rows, resize, label, name, options, value, errors  }}
              onChange={onChangeInputForm}
            />
        );
      default:
        return (
          <InputLabel
            {...{ label, name, type, errors }}
            value={value ?? ""}
            onChange={onChangeInputForm}
          />
        );
    }
  };

  return (
    <form>
      <Row>
        {inputsForm
          .filter(({ hide }) => !hide)
          .map(
            (
              {
                cols,
                label,
                name,
                type,
                options,
                value,
                rows,
                resize,
                multiple,
                accept
              },
              i
            ) => (
              <Grid key={i} {...{ cols }}>
                {handleTypeInput({
                  ...{
                    label,
                    name,
                    type,
                    options,
                    value,
                    rows,
                    resize,
                    multiple,
                    accept
                  },
                })}
              </Grid>
            )
          )}
      </Row>
    </form>
  );
}
