export const searchDefault = {
    params: {
        search: "",
        currentPage: 1,
        itemsPerPage: 10,
    }
}

export const itemsPerPageDefault = [
    { text: "10", value: 10 },
    { text: "20", value: 20 },
    { text: "30", value: 30 },
    { text: "40", value: 40 },
    { text: "50", value: 50 },
]

export const optionsRadioTestFunctionality = ["SIM", "NÃO", "NA"]