import React, { useEffect, useState } from "react";
import api from "../plugins/axios.js";

const Image = ({ imageURL, filename }) => {
  const [imageSrc, setImageSrc] = useState("");

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await api.get(imageURL, {
          responseType: "blob",
        });
        const imageBlob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        const imageUrl = URL.createObjectURL(imageBlob);
        setImageSrc(imageUrl);
      } catch (error) {
        console.error("Erro ao carregar a imagem:", error);
      }
    };

    fetchImage();
  }, [filename, imageURL]);

  return (
    <div>
      {imageSrc ? (
          <img src={imageSrc} alt={filename} className="img-fluid mb-3" height={80} />
      ) : (
        <small>Carregando...</small>
      )}
    </div>
  );
};

export default Image;
