import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import api from '../plugins/axios';
import { toastr } from 'react-redux-toastr'
import { setLoading, setLoadingText } from '../components/Loading/LoadingReducer';

export const asyncThunkFactory = (type, url, method, isLoading = true) => {
  return createAsyncThunk(type, async (params, { dispatch }, thunkAPI) => {
    dispatch(setLoading(isLoading));
    try {
      const response = await axios[method](url, { ...params });
      dispatch(setLoading(false));
      return response.data;
    } catch (error) {
      dispatch(setLoading(false));
      toastr.error('Erro', error.response.data.message || error.response.data.error);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  });
};

export const asyncApiThunkFactory = (type, url, method, loadingText) => {
  return createAsyncThunk(type, async (params, { dispatch }, thunkAPI) => {
    const baseURL = params?.id ? `${url}/${params?.id}` : url;
    const { headers, responseType } = params || "";
    dispatch(setLoading(true));
    dispatch(setLoadingText(loadingText ?? undefined));
    try {
      const response = await api[method](baseURL, { ...params }, { headers , responseType });
      if (!response.data.length) {
        dispatch(setLoading(false));
      }
      if (method === "post" || method === "put") {
        toastr.success("Sucesso", "Dados salvos!");
      } else if (method === "delete") {
        toastr.success("Sucesso", "Dados removidos!");
        // setTimeout(() => window.location.reload(), 1000)
      }
      dispatch(setLoading(false));
      if(responseType){
        const url = URL.createObjectURL(response.data);
        const link = document.createElement('a');
        const { params: { filename } } = params;
        link.href = url;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
        return { exportWordUrl: url }
      }
      return response.data;
    } catch (error) {
      dispatch(setLoading(false));
      toastr.error('Erro', error.response.data.message)
      return thunkAPI.rejectWithValue(error.response.data);
    }
  });
};

export const handlePending = (state) => {
  state.hasLoading = true;
};

export const handleFulfilled = (state, action) => {
  state.hasLoading = false;
  state.data = action.payload;
};

export const handleFulfilledSubmit = (state) => {
  state.hasLoading = false;
  state.data = null;
};

export const handleReject = (state, action) => {
  state.hasLoading = false;
  state.hasError = action.payload;
};