import { createSlice } from '@reduxjs/toolkit';
import { asyncApiThunkFactory, handlePending, handleReject, handleFulfilled, handleFulfilledSubmit } from '../../reducers/asyncThunkFactory';

const initialState = {
  item: null,
  data: {},
  exportWordUrl: null,
  hasLoading: false,
  hasError: null
};

export const index = asyncApiThunkFactory('reports/index', `${process.env.REACT_APP_REPORTS_V1}`, 'get');
export const show = asyncApiThunkFactory('reports/show', `${process.env.REACT_APP_REPORTS_V1}`, 'get');
export const create = asyncApiThunkFactory('reports/create', `${process.env.REACT_APP_REPORTS_V1}`, 'post');
export const update = asyncApiThunkFactory('reports/update', `${process.env.REACT_APP_REPORTS_V1}`, 'put');
export const remove = asyncApiThunkFactory('reports/remove', `${process.env.REACT_APP_REPORTS_V1}`, 'delete');
export const export_word = asyncApiThunkFactory('reports/export_word', `${process.env.REACT_APP_REPORTS_V1}/export_word`, 'get', 'Aguarde, isso pode demorar alguns segundos...');

const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // index
      .addCase(index.pending, handlePending)
      .addCase(index.fulfilled, handleFulfilled)
      .addCase(index.rejected, handleReject)
      // show
      .addCase(show.pending, handlePending)
      .addCase(show.fulfilled, handleFulfilled)
      .addCase(show.rejected, handleReject)
      // create
      .addCase(create.pending, handlePending)
      .addCase(create.fulfilled, handleFulfilledSubmit)
      .addCase(create.rejected, handleReject)
      // update
      .addCase(update.pending, handlePending)
      .addCase(update.fulfilled, handleFulfilledSubmit)
      .addCase(update.rejected, handleReject)
      // remove
      .addCase(remove.pending, handlePending)
      .addCase(remove.fulfilled, handleFulfilledSubmit)
      .addCase(remove.rejected, handleReject)
      // export_word
      .addCase(export_word.fulfilled, (state, action) => {
        state.exportWordUrl = action.payload.exportWordUrl;
      })
  },
});

export default reportSlice.reducer;