import { createSlice } from '@reduxjs/toolkit';
import { asyncApiThunkFactory, handlePending, handleReject, handleFulfilled, handleFulfilledSubmit } from '../asyncThunkFactory';

const initialState = {
  data: {},
  exportWordUrl: null,
  hasLoading: false,
  hasError: null
};

export const index = asyncApiThunkFactory('inspections/index', `${process.env.REACT_APP_INSPECTIONS_V1}`, 'get');
export const show = asyncApiThunkFactory('inspections/show', `${process.env.REACT_APP_INSPECTIONS_V1}`, 'get');
export const create = asyncApiThunkFactory('inspections/create', `${process.env.REACT_APP_INSPECTIONS_V1}`, 'post');
export const update = asyncApiThunkFactory('inspections/update', `${process.env.REACT_APP_INSPECTIONS_V1}`, 'put');
export const remove = asyncApiThunkFactory('inspections/remove', `${process.env.REACT_APP_INSPECTIONS_V1}`, 'delete');
export const export_word = asyncApiThunkFactory('inspections/export_word', `${process.env.REACT_APP_INSPECTIONS_V1}/export_word`, 'get', 'Aguarde, isso pode demorar alguns segundos...');

const inspectionSlice = createSlice({
  name: 'inspection',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // index
      .addCase(index.pending, handlePending)
      .addCase(index.fulfilled, handleFulfilled)
      .addCase(index.rejected, handleReject)
      // show
      .addCase(show.pending, handlePending)
      .addCase(show.fulfilled, handleFulfilled)
      .addCase(show.rejected, handleReject)
      // create
      .addCase(create.pending, handlePending)
      .addCase(create.fulfilled, handleFulfilledSubmit)
      .addCase(create.rejected, handleReject)
      // update
      .addCase(update.pending, handlePending)
      .addCase(update.fulfilled, handleFulfilledSubmit)
      .addCase(update.rejected, handleReject)
      // remove
      .addCase(remove.pending, handlePending)
      .addCase(remove.fulfilled, handleFulfilledSubmit)
      .addCase(remove.rejected, handleReject)
  },
});

export default inspectionSlice.reducer;