import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
/* Common / Form */
import FormGroup from "@/common/form/FormGroup";
/* Common / Layout */
import ContentHeader from "@/common/layouts/ContentHeader";
import Content from "@/common/layouts/Content";
import Grid from "@/common/layouts/Grid";
import Row from "@/common/layouts/Row";
/* Components */
import Card from "@/components/Card";
import { Button } from "@/components/Button";
/* Reducers */
import { visit, create } from "@/reducers/Inspections/InspectionVisitImagesReducer";

import CalloutInspection from "../../CalloutInspection";

function Index() {
  const { inspection_visit_id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    data: { inspection, inspection_visit },
  } = useSelector((state) => state.inspectionVisitImages);

  const [images, setImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);

  useEffect(() => {
      dispatch(visit({ id: inspection_visit_id }));
  }, [inspection_visit_id, dispatch]);

  const handleSubmit = () => {
    const formData = {
      images,
      inspection_visit_id,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    dispatch(create(formData)).then(() => {
      navigate(`/visits/${inspection_visit_id.id}/inspection`);
    });
  };

  const textsDetails = [
    { key: "status", label: "Status", cols: "12 3" },
    { key: "date_of_visit", label: "Realizada em", cols: "12 3" },
    { key: "item", label: "Item Nº", cols: "12 2" },
    { key: "responsible", label: "Responsável", cols: "12 4" },
    { key: "description", label: "Descrição", cols: "12 12" },
  ];

  return (
    <>
      <ContentHeader
        title="Incluir Imagens de Visita"
        previousRoute={`/visits/${inspection?.id}/inspection`}
      />
      <Content>
        <CalloutInspection
          id={inspection?.id}
          title={inspection?.title}
          company={inspection?.report?.company}
        />
        <Card
          title="Dados da Visita"
          iconTitle="list-alt"
          cardTools={
            <Button
              btnClass=" btn-tool"
              icon="save"
              disabled={images.length === 0}
              dataTooltipId="save-report-images"
              dataTooltipContent="Salvar"
              onClick={handleSubmit}
            />
          }
          cardBodyClass=" p-0"
        >
          <div className="pl-4 pr-4 pb-4">
            <Row>
              {textsDetails.map(({ key, label, cols }, i) => (
                <Grid key={i} {...{ cols }}>
                  <strong>{label}</strong>
                  <div>{inspection_visit?.[key]}</div>
                </Grid>
              ))}
            </Row>
            <hr />
            <Row>
              <Grid cols="12 12">
                <FormGroup>
                  <label>Imagens:</label>
                  <input
                    id="images"
                    name="images"
                    type="file"
                    multiple
                    accept="image/*"
                    className={`form-control${!images.length ? " is-invalid" : ""}`}
                    onChange={(e) => {
                      const files = Array.from(e.target.files);
                      setImages(files);
                      const previews = files.map((file) =>
                        URL.createObjectURL(file)
                      );
                      setImagePreviews(previews);
                    }}
                  />
                  {!images.length && (
                    <div className="invalid-feedback">Campo obrigatório.</div>
                  )}
                </FormGroup>
              </Grid>
              {imagePreviews &&
                imagePreviews.map((imageSrc, index) => (
                  <Grid key={index} cols="12 3">
                    <img
                      src={imageSrc}
                      alt={`Imagem ${index}`}
                      className="img-fluid"
                    />
                  </Grid>
                ))}
            </Row>
          </div>
        </Card>
      </Content>
    </>
  );
}

export default Index;
